import { SalesGroupRoute } from '@wolf/entities'
import { Select } from 'antd'
import { dayNames } from '../../../utils/day-names'
import { useUrlRouteParams } from '../hooks/useUrlRouteParams'

type ExpeditionSelectProps = {
  routes: Array<Pick<SalesGroupRoute, 'day' | 'route'>>
}

export const ExpeditionSelect: React.FC<ExpeditionSelectProps> = ({ routes }) => {

  const { routeParams, changeParams } = useUrlRouteParams()

  return (
    <div className='flex mb-4 ml-auto'>
      <span className='mr-2 self-center'>Ruta:</span>
      <Select
        autoFocus
        className='mr-4'
        style={{ minWidth: '12rem' }}
        allowClear
        size='middle'
        showSearch
        value={routeParams.route && routeParams.day !== undefined ? `${routeParams.route} - ${dayNames[routeParams.day]}` : undefined}
        onChange={(value) => {
          if (!value) {
            changeParams({})
          } else {
            const { route, day } = JSON.parse(value)
            changeParams({ route, day })
          }
        }}
        options={routes && routes.map((route: Pick<SalesGroupRoute, 'day' | 'route'>) => (
          { label: `${route.route} - ${dayNames[route.day]}`, value: JSON.stringify(route) }
        ))}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
        }
      />
    </div>
  )

}
