import { Input, InputNumber, Table, TableColumnsType } from 'antd'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Title } from '../../components/Title'
import { usePageOutletContext } from '../../page-outlet-context'
import { trpc } from '../../utils/trpc'

type DataType = {
  productId: number
  name: string
  ordered: number
  production: number
  stock: number
  picked: number
  productionVsOrdered: number
  pickedVsOrdered: number
}

export const Stats: React.FC = () => {

  const [pageSize, setPageSize] = useState<number>(50)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [productName, setProductName] = useState<string | undefined>(undefined)
  const [searchProductId, setSearchProductId] = useState<number | null>(null)

  const { salesGroupFilter, sourceFilter } = usePageOutletContext()

  const productsQuery = trpc.products.getProductsStats.useQuery({
    pagination: {
      currentPage,
      pageSize,
    },
    salesGroupFilter,
    productName,
    productId: searchProductId ?? undefined,
    sourceId: sourceFilter.serialId
  }, {
    enabled: salesGroupFilter.salesGroupId > 0,
    refetchOnWindowFocus: false
  })

  const classNameByValue = (value: number) => {
    if (value < 0) {
      return 'text-red-600'
    }
  }

  const columns: TableColumnsType<DataType> = [
    {
      title: 'ID', key: 'productId', dataIndex: 'productId'
    },
    {
      title: 'Nombre', key: 'name', dataIndex: 'name', className: 'w-1/3'
    },
    {
      title: 'Pedidos', key: 'ordered', dataIndex: 'ordered'
    },
    {
      title: 'Producidos', key: 'production', dataIndex: 'production'
    },
    {
      title: 'Stock', key: 'stock', dataIndex: 'stock'
    },
    {
      title: 'Montados', key: 'picked', dataIndex: 'picked'
    },
    {
      title: 'Producidos-Pedidos', key: 'productionVsOrdered', dataIndex: 'productionVsOrdered',
      render: (value: number) => <span className={classNameByValue(value)}>{value}</span>
    },
    {
      title: 'Producidos-Pedidos (LeadTime)', key: 'productionVsOrderedWithLeadTime', dataIndex: 'productionVsOrderedWithLeadTime',
      render: (value: number) => <span className={classNameByValue(value)}>{value}</span>
    },
    {
      title: 'Montados-Pedidos', key: 'pickedVsOrdered', dataIndex: 'pickedVsOrdered',
      className: 'bg-yellow-100',
      render: (value: number) => <span className={`${classNameByValue(value)}`}>{value}</span>
    }
  ]

  const onPaginationChange = (current: number, pageSize: number) => {
    setCurrentPage(current)
    setPageSize(pageSize)
  }

  useEffect(() => {
    setCurrentPage(1)
    // We need stringify because the parameters are objects, and even if none of them change, the reference does change
  }, [JSON.stringify({ productName, searchProductId })])

  return (
    <>
      <Helmet
        title='Estadísticas - Wolf'
      />

      <Title>Estadísticas</Title>

      <div className='flex mb-3'>
        <p className="w-fit mx-2">Id de producto:</p>
        <InputNumber
          className="flex self-center w-40 h-8 pl-2"
          placeholder="1234"
          min={0}
          value={searchProductId}
          onChange={setSearchProductId}
        />
        <p className='w-fit mx-2'>Nombre de producto:</p>
        <Input
          className='flex self-center w-40 h-8 pl-2'
          placeholder='Nombre'
          value={productName}
          onChange={(event) => {
            setCurrentPage(1)
            if ((event.target.value)) return setProductName(event.target.value)
            return setProductName(undefined)
          }}
        />
      </div>

      <Table
        className='border-solid border border-slate-200 rounded-lg mb-8'
        columns={columns}
        dataSource={productsQuery.data?.result}
        loading={productsQuery.isLoading}
        rowKey={(productId) => productId.name}
        pagination={
          {
            className: 'pr-4',
            total: productsQuery.data?.total,
            pageSize,
            current: currentPage,
            showSizeChanger: true,
            onChange: onPaginationChange
          }
        }
      />
    </>
  )
}
