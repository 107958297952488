import React from 'react'

type Props = {
  children: React.ReactNode
}

export const Title: React.FC<Props> = ({ children }) => {
  return (
    <h1 className='uppercase text-xl pb-2 m-0'>
      {children}
    </h1>
  )
}
