import { trpc } from '../../../../utils/trpc'
import { PdfFrame } from '../../../../components/labels/PdfFrame'
import bwipjs from 'bwip-js'
import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { format, secondsToMinSec } from '@wolf/dates'
import { usePageOutletContext } from '../../../../page-outlet-context'
import { productToOldBarcode } from '@wolf/barcodes'
import { getTranslations } from './TranslationTemplate'

/**
 * transform kcal to kj
 */
const kcalToKj = (kcal: number): string => {
  return (kcal * 4.184).toFixed(1).toString()
}

type Props = {
  productId: number
  expirationDate: Date
  quantity: number
  onClose: () => void
}

export const SingleLabelTemplate: React.FC<Props> = ({
  productId,
  expirationDate,
  quantity,
  onClose,
}) => {

  const { sourceFilter, notificationInstance } = usePageOutletContext()

  const t = getTranslations(sourceFilter.language)

  const showError = (errorMessage: string) =>
    notificationInstance.error({
      message: 'Error en formulario',
      description: errorMessage,
    })

  const productQuery = trpc.products.getProductById.useQuery({
    productId: productId,
    sourceId: sourceFilter.serialId,
  }, {
    retry: false,
    onError: (error) => {
      showError(error.message)
    },
  })

  const contentArray = new Array(quantity).fill(0)

  if (productQuery.isLoading) {
    return <LoadingOutlined />
  }

  if (!productQuery.data) {
    return <></>
  }

  const { data: product } = productQuery

  const barcodes = contentArray.map((_, index) => {
    /* TODO: Uncomment once we migrated fully from trastaca
    const productCode = {
      sourceId: source.serialId,
      workspaceId: workspace.serialId,
      salesGroupId,
      productId: product.productId,
      date: now,
      index: index + 1,
    }

    const dataMatrixCodeText = productV2ToCode(productCode)
    */

    const productOldCode = {
      productId: product.productId,
      date: new Date(),
      index: index + 1,
    }

    const barcodeText = productToOldBarcode(productOldCode)

    return {
      /* TODO: Uncomment once we migrated fully from trastaca
      datamatrix: {
        imageDataURL: bwipjs
          .toCanvas(document.createElement('canvas'), {
            bcid: 'datamatrix',
            text: dataMatrixCodeText,
          })
          .toDataURL('image/png'),
        codeText: dataMatrixCodeText
      },
      */
      barcode: {
        imageDataURL: bwipjs
          .toCanvas(document.createElement('canvas'), {
            bcid: 'code128',
            text: barcodeText,
          })
          .toDataURL('image/png'),
        codeText: `L-${barcodeText}`
      }
    }
  })

  const getIngredientsWithAllergensFormatted = (dishIngredients: string) => {
    const hashtagsAppearances = dishIngredients.split('##').length - 1
    const slashAppearances = dishIngredients.split('//').length - 1
    const sameOpeningsAsClosures = hashtagsAppearances === slashAppearances

    // If the opening and closing signs are not the same we return the ingredients without formatting.
    const formattedDishIngredients = dishIngredients.replaceAll('##', sameOpeningsAsClosures ? '<span class="ingredients-text--with-allergen">' : '').replaceAll('//', sameOpeningsAsClosures ? '</span>' : '')

    return <span dangerouslySetInnerHTML={{ __html: formattedDishIngredients }} />
  }

  return (
    <PdfFrame onClose={onClose} waitingTime={10000}>
      <link
        type="text/css"
        rel="Stylesheet"
        href="/styles/SingleLabelTemplate.css"
      />
      {contentArray.map((_, index) => {
        return (
          <div className="content" key={index}>
            <div className="column">
              <div className="column1">
                <div className="micro">
                  {product.tuppers.map((tupper) => {
                    return (
                      <div className="micro1" key={tupper.name}>
                        <img
                          className="micro-img"
                          src={`/images/${tupper.cooking !== 0 ? 'heat' : 'noheat'}.svg`}
                        />
                        <div className="micro-text">
                          <p className="micro-time">
                            {tupper.cooking !== 0
                              ? secondsToMinSec(tupper.cooking)
                              : 'No calentar'}
                          </p>
                          {product.tuppers.length > 1 && <p className="micro-name">{tupper.label ?? tupper.name}</p>}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="column2 topcolumn">
                <p className="logo">
                  <img className="logo" src="/images/pan.svg" />
                </p>
              </div>
            </div>

            <div className="column">
              <div className="column1">
                <div className="ingredients">
                  <p className="ingredients-text">
                    <b>{product.name}. </b>
                    {product.ingredients
                      ? <><b>{t.ingredients}:</b> {getIngredientsWithAllergensFormatted(product.ingredients)}</>
                      : ''
                    }
                  </p>
                </div>

                <div className="nutricion">
                  <table>
                    <tbody>
                      <tr>
                        <th className="left">{t.nutritionFactsTitle}</th>

                        <th className="right">{t.per100g}</th>

                        <th className="right">
                          {t.serving} {product.weight} g
                        </th>
                      </tr>

                      {Object.entries(product.nutritionFacts).map(
                        ([key, value]) => {
                          const totalQuantity =
                            ((value * product.weight) / 100)
                          return (
                            <tr key={key}>
                              <td className="left">{t.nutritionFacts[key]}</td>
                              <td className="right">
                                {key === 'energy'
                                  ? `${kcalToKj(value)} kJ / ${value} kcl`
                                  : `${value} g`}
                              </td>
                              <td className="right">
                                {key === 'energy'
                                  ? `${kcalToKj(totalQuantity)} kJ / ${totalQuantity.toFixed(2)} kcl`
                                  : `${totalQuantity.toFixed(2)} g`}
                              </td>
                            </tr>
                          )
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="column2 column2-left">
                <div>
                  <p className="date-text">{t.expirationDate}:</p>
                  <p className="date">
                    {format(expirationDate, 'dd/MM/yyyy')}
                  </p>

                  <p className="date-information">
                    {t.protectAtmosphere}
                  </p>
                </div>
                <div className="allergen">
                  {Object.entries(product.allergens).map(([key, value]) => {
                    if (value)
                      return (
                        <div className="allergen-icon" key={key}>
                          <img src={`/images/${key}.svg`} />
                          <p className="allergen-icon-text">{t.allergens[key]}</p>
                        </div>
                      )
                  })}

                  <p className="information-allergen">
                    {t.allergensWarning}
                  </p>
                </div>
                <div className="code">
                  {/* TODO: Uncomment once we migrated fully from trastaca
                  <div className="code-icon">
                    <img width={80} src={barcodes[index].datamatrix.imageDataURL} />
                    <p className="code-icon-text">{barcodes[index].datamatrix.codeText}</p>
                  </div>
                  */}

                  <div className="code-icon">
                    <img width={120} src={barcodes[index].barcode.imageDataURL} />
                    <p className="code-icon-text">{barcodes[index].barcode.codeText}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="name-dish">
              <div className="name-dish-title">
                <strong>{product.name}</strong>
              </div>
            </div>
          </div>
        )
      })}
    </PdfFrame>
  )
}
