import { WarningTwoTone } from '@ant-design/icons'
import { Button, Alert } from 'antd'

type StockAlertProps = {
  title: React.ReactNode
  text: React.ReactNode
  onConfirm: () => void
  onCancel: () => void
}

export const StockAlert = ({ title, text, onConfirm, onCancel }: StockAlertProps) => {
  return <Alert
    message={<span><WarningTwoTone className='mr-2' />{title}</span>}
    description={<span>{text}</span>}
    type="info"
    className='mt-4 block'
    action={
      <div className='mt-2'>
        <Button size="small" type="primary" className='mr-2' onClick={onConfirm}>
          Confirmar
        </Button>
        <Button size="small" type='primary' ghost onClick={onCancel}>
          Cancelar
        </Button>
      </div>
    }
  />
}
