import generatePicker from 'antd/lib/date-picker/generatePicker'
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'
import esES from 'antd/lib/locale/es_ES'

// Correcting the locale setting for Spanish language.
// There's an inconsistency in how antd sets the Spanish locale with dateFns, so we explicitly set it to 'es'.
if (esES.DatePicker?.lang.locale) {
  esES.DatePicker.lang.locale = 'es'
}

const GeneratedDatePicker = generatePicker<Date>(dateFnsGenerateConfig)
const GeneratedRangePicker = GeneratedDatePicker.RangePicker


export const DatePicker: React.FC<React.ComponentProps<typeof GeneratedDatePicker>> = (props) => {
  return <GeneratedDatePicker
    locale={esES.DatePicker}
    format={'dd/MM/yyyy'}
    {...props}
  />
}

export const RangePicker: React.FC<React.ComponentProps<typeof GeneratedRangePicker>> = (props) => {
  return <GeneratedRangePicker
    locale={esES.DatePicker}
    format={'dd/MM/yyyy'}
    {...props}
  />
}

