import { format } from '@wolf/dates'
import { PdfFrame } from '../labels/PdfFrame'
import { usePageOutletContext } from '../../page-outlet-context'
import { dayNames } from '../../utils/day-names'
import { RouterOutput, trpc } from '../../utils/trpc'
import { SalesGroupRoute } from '@wolf/entities'

type ExpeditionBox = RouterOutput['boxes']['getRouteBoxesSummary'][number]['data']['unchecked'][0]

type ExpeditionPrintProps = {
  boxes: Array<ExpeditionBox>
  route: Pick<SalesGroupRoute, 'day' | 'route'>
  onClose: () => void
}

type DeliveriesAcc = {
  [deliveryGroupId: number]: {
    deliveryGroup: ExpeditionBox['deliveryGroup']
    deliveryGroupId: number
    picking: ExpeditionBox['picking']
  }
}

export const PackingListTemplate = ({ boxes, route, onClose }: ExpeditionPrintProps) => {
  const { sourceFilter } = usePageOutletContext()

  const productIds = Array.from(
    boxes
      .flatMap((box) => box.picking)
      .flatMap((picking) => picking.productId)
      .reduce<Set<number>>((productIds, productId) => productIds.add(productId), new Set())
  )

  const productWeights = trpc.products.getProductsWeightByIds.useQuery(
    {
      sourceId: sourceFilter.serialId,
      productIds,
    },
    {
      enabled: productIds.length > 0,
    }
  ).data

  const deliveries = Object.values(
    boxes.reduce((deliveriesAcc: DeliveriesAcc, box) => {
      const { deliveryGroup, deliveryGroupId, picking } = box

      if (deliveriesAcc[deliveryGroupId]) {
        const updatedItem = {
          ...deliveriesAcc[deliveryGroupId],
          picking: deliveriesAcc[deliveryGroupId].picking.concat(picking),
        }
        return {
          ...deliveriesAcc,
          [deliveryGroupId]: updatedItem,
        }
      } else {
        return {
          ...deliveriesAcc,
          [deliveryGroupId]: {
            deliveryGroup,
            deliveryGroupId,
            picking,
          },
        }
      }
    }, {})
  ).sort((a, b) => {
    const stopA = a.deliveryGroup.currentRoute?.stop || 0
    const stopB = b.deliveryGroup.currentRoute?.stop || 0

    return stopA - stopB
  })

  const calculateDeliveryWeight = (picking: ExpeditionBox['picking']) => {
    if (!productWeights) return 0

    const deliveryWeight = picking.reduce((weightAcc, picking) => {
      const productId = picking.productId
      const quantity = picking.quantity

      const productWeight = productWeights[productId]

      if (!productWeight) {
        return weightAcc
      }

      return weightAcc + productWeight * quantity
    }, 0)

    // Transform Gr to Kg
    return deliveryWeight * 0.001
  }

  const totalWeight = deliveries
    .map((delivery) => calculateDeliveryWeight(delivery.picking))
    .reduce((total, deliveryWeight) => total + deliveryWeight, 0)

  const calculateTotalBoxQuantity = deliveries.reduce(
    (totalBoxes, delivery) => {
      return totalBoxes + delivery.deliveryGroup.boxes.length
    },
    0
  )

  return (
    <PdfFrame onClose={onClose} waitingTime={1000}>
      <link
        type="text/css"
        rel="Stylesheet"
        href="/styles/PackingListTemplate.css"
      />

      <link
        href="https://fonts.googleapis.com/css?family=Open+Sans"
        rel="stylesheet"
      />

      <div>
        <div className="header">
          <img className="logo" src="/images/pan.svg" />
          <ul>
            <li>
              <b>Wetaca | Come bien todos los días</b>
            </li>
            <li>Calle de la Resina 34, 28021 Madrid</li>
            <li>Atención al Cliente + 34 648 617 854 | info@wetaca.com</li>
            <li>Alcasal Soluciones de Alimentación S.L CIF: B87052007</li>
          </ul>
        </div>

        <h3 className="text-center wfull"> ALBARAN DE ENTREGA </h3>
        <h4 className="text-center wfull">
          {`${route.route} - ${dayNames[route.day]}`}
        </h4>
        <table className="table table-bordered wfull">
          <thead>
            <tr>
              <th className="text-center" style={{ width: '50%' }}>
                {' '}
                TIPO{' '}
              </th>
              <th className="text-center" style={{ width: '50%' }}>
                {' '}
                FECHA{' '}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td align="center" className="align-middle">
                Frío (2-6)ºC
              </td>
              <td align="center" className="align-middle">
                {deliveries[0] && deliveries[0].deliveryGroup.currentRoute?.scheduledAt && format(deliveries[0].deliveryGroup.currentRoute?.scheduledAt, 'dd/MM/yyyy')}
              </td>
            </tr>
          </tbody>
        </table>

        <table className="table table-bordered wfull">
          <thead>
            <tr>
              <th className="text-center" style={{ width: '5%' }}>
                Num
              </th>
              <th className="text-center" style={{ width: '10%' }}>
                Hora
              </th>
              <th className="text-center" style={{ width: '10%' }}>
                Id Envío
              </th>
              <th className="text-center" style={{ width: '25%' }}>
                Datos Cliente
              </th>
              <th className="text-center" style={{ width: '20%' }}>
                Observaciones
              </th>
              <th className="text-center" style={{ width: '5%' }}>
                Bultos
              </th>
              <th className="text-center" style={{ width: '10%' }}>
                Peso (kg)
              </th>
              <th className="text-center" style={{ width: '20%' }}>
                Firma
              </th>
            </tr>
          </thead>
          <tbody>
            {deliveries.map((delivery) => {
              return (
                <tr key={delivery.deliveryGroup.currentRoute?.stop}>
                  <td align="center" className="align-middle">
                    {delivery.deliveryGroup.currentRoute?.stop}
                  </td>
                  <td align="center" className="align-middle">
                    {delivery.deliveryGroup.currentRoute?.scheduledAt && format(delivery.deliveryGroup.currentRoute?.scheduledAt, 'HH:mm')}
                  </td>
                  <td align="center" className="align-middle">
                    {delivery.deliveryGroupId}
                  </td>
                  <td align="center" className="align-middle">
                    {`${delivery.deliveryGroup.address.firstname} ${delivery.deliveryGroup.address.lastname}`}
                    <br />
                    {`${delivery.deliveryGroup.address.street},
                    ${delivery.deliveryGroup.address.number},
                    ${delivery.deliveryGroup.address.floor},
                    ${delivery.deliveryGroup.address.city},
                    ${delivery.deliveryGroup.address.zipcode}
                    `}
                    <br />
                    {`Tlfno: ${delivery.deliveryGroup.address.phone}`}
                  </td>
                  <td align="center" className="align-middle">
                    {delivery.deliveryGroup.address.notes || ''}
                  </td>
                  <td align="center" className="align-middle">
                    {delivery.deliveryGroup.boxes.length}
                  </td>
                  <td align="center" className="align-middle">
                    {calculateDeliveryWeight(delivery.picking).toFixed(2)}
                  </td>
                  <td align="center" className="align-middle"></td>
                </tr>
              )
            })}
          </tbody>
        </table>

        <table className="table table-bordered" align="right">
          <tbody>
            <tr>
              <th>Bultos totales:</th>
              <td>{calculateTotalBoxQuantity}</td>
            </tr>
            <tr>
              <th>Peso total (kg) </th>
              <td>{totalWeight.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>

        <table className="table table-bordered wfull">
          <thead>
            <tr>
              <th align="left">Observaciones</th>
            </tr>
          </thead>

          <tbody>
            <tr className="table-empty">
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>

        <table className="table table-bordered wfull">
          <thead>
            <tr>
              <th className="text-center" style={{ width: '60%' }}>
                TRANSPORTISTA
              </th>
              <th className="text-center" style={{ width: '40%' }}>
                ALCASAL SOLUCIONES SL
              </th>
            </tr>
          </thead>

          <tbody>
            <tr className="table-carrier">
              <td>
                Nombre: <br />
                Matrícula: <br />
                DNI: <br />
                Firma: <br />
              </td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </div>
    </PdfFrame>
  )
}
