import { Button, Form, Input, Space } from 'antd'
import { usePageOutletContext } from '../../page-outlet-context'

type PrinterConfigurationFormInput = {
  ipAddress: string
}

export const PrinterConfiguration: React.FC = () => {

  const { notificationInstance } = usePageOutletContext()
  const [form] = Form.useForm<PrinterConfigurationFormInput>()
  const initialIpAddress = localStorage.getItem('printer_ip_address')

  const showSuccess = () =>
    notificationInstance.success({
      message: 'Dirección IP actualizada',
      description: 'Se ha actualizado con éxito la dirección IP configurada para la impresora',
      duration: 3
    })

  const onSaveConfiguration = async ({ ipAddress }: PrinterConfigurationFormInput) => {
    localStorage.setItem('printer_ip_address', ipAddress)
    showSuccess()
  }

  return (
    <>
      <p>Configuración de la dirección IP local que va a ser asignada a la impresora. Por ejemplo 10.0.0.0</p>
      <Form
        form={form}
        onFinish={onSaveConfiguration}
        initialValues={{
          ipAddress: initialIpAddress ?? undefined
        }}
      >
        <Space.Compact>
          <Form.Item name="ipAddress" label='Dirección IP'>
            <Input placeholder="10.0.0.0" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">Guardar</Button>
          </Form.Item>
        </Space.Compact>
      </Form>
    </>
  )
}
