import React, { useState } from 'react'
import {
  Form,
  Space,
  Button,
  Select,
  InputNumber,
  Card,
  Alert,
} from 'antd'
import { trpc } from '../../utils/trpc'
import { SalesGroupModel } from '@wolf/entities'
import { usePageOutletContext } from '../../page-outlet-context'
import { Helmet } from 'react-helmet-async'
import { DatePicker } from '../../components/DatePicker'
import { format, isAfter, isBefore } from '@wolf/dates'

type FormValues = {
  deliveryGroupId: number
  salesGroupId: number
  deliveryDay: Date
}

export const UpdateDeliverySalesGroup: React.FC = () => {
  const { notificationInstance, sourceFilter } = usePageOutletContext()

  const [salesGroup, setSalesGroup] = useState<SalesGroupModel | undefined>(
    undefined
  )

  const [form] = Form.useForm()

  const salesGroups = trpc.salesGroups.getSalesGroups.useQuery(sourceFilter.serialId, {
    refetchOnWindowFocus: false
  }).data

  const updateDeliveryMutation =
    trpc.logistics.updateDeliverySalesGroup.useMutation()

  const onFinish = async (values: FormValues) => {
    updateDeliveryMutation.mutate(
      {
        deliveryGroupId: values.deliveryGroupId,
        salesGroupId: values.salesGroupId,
        sourceId: sourceFilter.serialId,
        deliveryDay: new Date(values.deliveryDay),
      },
      {
        onSuccess: (deliveryGroup) => {
          showSuccess(deliveryGroup.serialId, deliveryGroup.salesGroupId)
          form.resetFields()
        },
        onError: (error) => {
          showError(error.message)
        },
      }
    )
  }

  const showSuccess = (deliveryGroupId: number, salesGroupId: number) =>
    notificationInstance.success({
      message: 'Correcto',
      description: `El pedido ${deliveryGroupId} se ha movido a la semana ${salesGroupId}`,
      duration: 2,
    })

  const showError = (errorMessage: string) =>
    notificationInstance.error({
      message: 'Error',
      description: errorMessage,
    })

  return (
    <>
      <Helmet title="Modificar semana de envío - Wolf" />
      <Alert
        message="Instrucciones para cambiar pedido de SalesGroup:"
        description={
          <ul>
            <li>Introduce el id del envío, la nueva semana y el nuevo día de entrega.</li>
            <li>Ten en cuenta que el sistema considerará que las cajas del pedido ya no se encontrarán en su anterior expedición.</li>
            <li>En OptimoRoute, el envío se moverá al día seleccionado en el formulario.</li>
            <li>El pedido estará sin ruta hasta que se realice la sinconización con OptimoRoute.</li>
          </ul>
        }
        type="info"
        showIcon
      />
      <Card title="Modificar semana de envío" className="my-4">
        <Form layout="inline" onFinish={onFinish} form={form}>
          <Form.Item
            label="ID de envío"
            name="deliveryGroupId"
            rules={[{ required: true, message: 'Introducir Id del pedido' }]}
          >
            <InputNumber min={0} placeholder="1234" />
          </Form.Item>
          <Form.Item
            label="Semana Wetaca"
            name="salesGroupId"
            rules={[{ required: true, message: 'Seleccionar semana' }]}
            className="w-1/3"
          >
            <Select
              showSearch
              onChange={(value) => {
                const sale = salesGroups?.find(
                  (salesGroup) => salesGroup.salesGroupId === value
                )
                setSalesGroup(sale)

                form.setFieldsValue({
                  deliveryDay: sale?.fromDate,
                })
              }}
              options={
                salesGroups &&
                salesGroups.map((salesGroup) => {
                  return {
                    label: `${salesGroup.salesGroupId} -
                    ${format(salesGroup.fromDate, 'dd/MM/yyyy')} -> ${format(salesGroup.toDate, 'dd/MM/yyyy')}`,
                    value: salesGroup.salesGroupId,
                  }
                })
              }
            />
          </Form.Item>

          <Form.Item
            label="Día de entrega"
            name="deliveryDay"
            rules={[{ required: true, message: 'Introducir fecha' }]}
          >
            <DatePicker
              disabled={!salesGroup}
              disabledDate={(current) => {
                if (!salesGroup) {
                  return false
                }
                return current && (isBefore(current, salesGroup.fromDate) || isAfter(current, salesGroup.toDate))
              }}
            />
          </Form.Item>

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Guardar
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </>
  )
}
