import { trpc } from './utils/trpc'

export const useDefaultConfiguration = () => {
  const configQuery = trpc.config.getDefaultConfig.useQuery(undefined, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 // 1 hour
  })

  if (configQuery.isLoading || !configQuery.data) {
    return {
      isLoading: true
    } as const
  }

  return {
    isLoading: false,
    ...configQuery.data
  } as const
}
