import { Models } from '@wolf/api'
import { useSearchParams } from 'react-router-dom'

type RouteRequirementsParams = Array<Models.RouteRequirementFilter> | undefined

type RouteRequirementsParamsResponse = {
  requirementsParams: RouteRequirementsParams
  changeParams: (params: RouteRequirementsParams) => void
}

export const URL__REQUIREMENTS_PARAMS = {
  ROUTE_REQUIREMENTS: 'routeRequirements'
} as const

export const useUrlRouteRequirementsParams = (): RouteRequirementsParamsResponse => {
  const [searchParams, setSearchParams] = useSearchParams()

  const changeParams = (requirements: RouteRequirementsParams) => {

    if (requirements && requirements.length > 0) {
      searchParams.set(URL__REQUIREMENTS_PARAMS.ROUTE_REQUIREMENTS, requirements.map((req) => req).toString())
    } else {
      searchParams.delete(URL__REQUIREMENTS_PARAMS.ROUTE_REQUIREMENTS)
    }

    return setSearchParams(searchParams)
  }

  const requirements = searchParams.get(URL__REQUIREMENTS_PARAMS.ROUTE_REQUIREMENTS)?.split(',')

  if (!requirements) {
    return {
      requirementsParams: requirements,
      changeParams
    }
  }

  const validRequirements = requirements.reduce<Array<Models.RouteRequirementFilter>>((requirementsMap, requirement) => {
    if (requirement === 'withIncompletedBoxes'
      || requirement === 'withBoxesOnAnotherRoute'
      || requirement === 'withBoxesFromAnotherRoute'
      || requirement === 'incompleted') {
      requirementsMap.push(requirement)
    }
    return requirementsMap
  }, [])

  return {
    requirementsParams: validRequirements,
    changeParams
  }

}