import { RouterOutput } from '../../../utils/trpc'
import Table, { ColumnsType } from 'antd/es/table'
import { Popover } from 'antd'
import { STATE_COLOURS } from '../../../state-colours'
import { dayNames } from '../../../utils/day-names'
import { DeleteOutlined } from '@ant-design/icons'
import { ExpeditionTypes, EXPEDITION_TYPES } from './Expedition'
import { SalesGroupRoute } from '@wolf/entities'
import { Link } from 'react-router-dom'

type ExpeditionBox = RouterOutput['boxes']['getRouteBoxesSummary'][number]['data']['unchecked'][0]

type ExpeditionTableProps = {
  boxes: Array<ExpeditionBox>
  type: ExpeditionTypes
  addBoxExpeditionMutationFn?: (body: Pick<SalesGroupRoute, 'day' | 'route'> & { boxId: number }) => void
  classNameByRouteState: () => string | undefined
  onDeleteCheck?: () => void
}
export const ExpeditionTable: React.FC<ExpeditionTableProps> = ({ boxes, type, addBoxExpeditionMutationFn, classNameByRouteState, onDeleteCheck }) => {

  const getBoxIdContent = (box: ExpeditionBox) => {
    return <>
      ID CAJA: <span className='font-bold'>{box.serialId}</span> <br />
      {type !== 'FROM_ANOTHER_ROUTE' && <>PARADA: <span className='font-bold'>{box.deliveryGroup.currentRoute?.stop}</span> <br /></>}
      ID GRUPO DE ENVÍO: <span className='font-bold'>{box.deliveryGroupId}</span> <br />
      CAJA DEL ENVÍO: <span className='font-bold'>{box.deliveryGroupIndex} / {box.deliveryGroup.boxes.length}</span>
    </>
  }

  const getCustomerName = ({ firstname, lastname }: { firstname: string; lastname: string }) => {
    return firstname + ' ' + lastname
  }

  const columns: ColumnsType<ExpeditionBox> = [
    {
      dataIndex: [],
      title: 'Caja',
      key: 'box',
      className: 'px-2 overflow-hidden',
      render: (box: ExpeditionBox) => {
        return (<Popover placement='bottom' content={getBoxIdContent(box)}>
          <Link className='text-black hover:text-gray-800' to={`/picking/${box.serialId}`}>{box.serialId}</Link>
        </Popover>)
      }
    },
    {
      dataIndex: ['deliveryGroup', 'address'],
      title: 'Cliente',
      key: 'client',
      className: 'px-2 max-w-6 overflow-hidden whitespace-nowrap text-ellipsis',
      render: (address: ExpeditionBox['deliveryGroup']['address'], record: ExpeditionBox) => {
        return (<Popover placement='bottomLeft' content={getCustomerName(address)}>
          {getCustomerName(address)} {record.pickingState === 'UNMOUNT' ? <strong>(DESMONTAR)</strong> : ''}
        </Popover>)
      }
    },
    {
      dataIndex: type === EXPEDITION_TYPES.ON_ANOTHER_ROUTE ? 'expeditionEntries' : ['deliveryGroup', 'routeHistory'],
      title: 'Ruta',
      key: 'route',
      className: `${type === EXPEDITION_TYPES.ON_ANOTHER_ROUTE || type === EXPEDITION_TYPES.FROM_ANOTHER_ROUTE ? 'px-2' : 'hidden'}`,
      render: (routes: ExpeditionBox['expeditionEntries'] | ExpeditionBox['deliveryGroup']['routeHistory']) => {
        if (routes.length > 0) {
          const { route, day } = routes[routes.length - 1]
          return `${route} - ${dayNames[day]}`
        }
      }
    },
    {
      dataIndex: 'serialId',
      title: '',
      key: 'serialId',
      className: `${type === EXPEDITION_TYPES.CHECKED || type === EXPEDITION_TYPES.FROM_ANOTHER_ROUTE ? '!px-0 max-w-3' : 'hidden'}`,
      render: (serialId: number) => {
        return (
          <button
            className={`${STATE_COLOURS.ERROR} rounded h-6 border border-solid border-black cursor-pointer`}
            onClick={() => {
              if (addBoxExpeditionMutationFn && onDeleteCheck) {
                onDeleteCheck()
                return addBoxExpeditionMutationFn({ boxId: serialId, route: 'NO_ROUTE', day: -1 })
              }
            }}
          >
            <DeleteOutlined style={{ color: 'black' }} />
          </button>
        )
      },
    },
  ]

  const classNameByBoxPicking = (box: ExpeditionBox) =>
    (box.pickingState === 'COMPLETED' ? STATE_COLOURS.EMPTY : STATE_COLOURS.ERROR)

  return (<>
    <p className={`${classNameByRouteState()} rounded font-extrabold text-3xl mt-0 mb-3`}>{boxes.length}</p>
    {boxes.length > 0 && <Table
      className='border-solid border border-slate-200 rounded-lg mb-6 mt-4 overflow-hidden'
      columns={columns}
      dataSource={boxes}
      pagination={false}
      rowKey={(box) => box.serialId}
      rowClassName={(record) => {
        return classNameByBoxPicking(record)
      }}
    />}
  </>)
}
