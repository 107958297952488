import React, { useState } from 'react'
import { Form, InputNumber, Space, Button, Card, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { trpc } from '../../../utils/trpc'
import { AdjustOp } from '@wolf/entities'
import { LoadingOutlined } from '@ant-design/icons'
import { usePageOutletContext } from '../../../page-outlet-context'
import { DatePicker } from '../../../components/DatePicker'

type StocksEntryFormProps = {
  onSuccess: () => void
}

export const StocksEntryForm: React.FC<StocksEntryFormProps> = (props) => {
  const [form] = Form.useForm()
  const [stockSign, setStockSign] = useState<'add' | 'minus'>('minus')

  const { sourceFilter, workspace, notificationInstance, salesGroupFilter } =
    usePageOutletContext()

  const createStockMutation = trpc.stockEntries.createStockEntry.useMutation({
    onError: (error) => {
      showError(error.message)
    },
    onSuccess: (success) => {
      showSuccess(success.productId)
      form.resetFields()
      props.onSuccess()
    },
  })

  const showError = (errorMessage: string) =>
    notificationInstance.error({
      message: 'Error en formulario',
      description: errorMessage,
    })

  const showSuccess = (productId: number) =>
    notificationInstance.success({
      message: 'Formulario correcto',
      description: `El stock del producto ${productId} ha sido modificado`,
      duration: 2,
    })

  const onFinish = (values: AdjustOp) => {

    const { quantity, ...stock } = values

    createStockMutation.mutate({
      ...stock,
      quantity: stockSign === 'add' ? quantity : -quantity,
      sourceId: sourceFilter.serialId,
      workspaceId: workspace.serialId,
      op: 'ADJUST',
    })
  }

  const selectBefore = (
    <Select
      defaultValue={stockSign}
      options={[
        { label: '+', value: 'add' },
        { label: '-', value: 'minus' }
      ]}
      onChange={(value) => setStockSign(value)}
    />
  )

  if (!salesGroupFilter.salesGroupId || salesGroupFilter.salesGroupId < 0) return <LoadingOutlined />

  return (
    <Card title="Modificar stock" bordered={true} className="mt-4">
      <Form layout="inline" form={form} onFinish={onFinish}>
        <Form.Item
          label="Producto ID"
          name="productId"
          rules={[{ required: true, message: 'Introducir Id producto' }]}
        >
          <InputNumber min={0} placeholder="1234" />
        </Form.Item>
        <Form.Item
          label="Sales Group ID"
          name="salesGroupId"
          initialValue={salesGroupFilter.salesGroupId}
          rules={[{ required: true, message: 'Introducir Id sales group' }]}
        >
          <InputNumber min={0} placeholder="202234" />
        </Form.Item>
        <Form.Item label="Lote" name="lotId">
          <InputNumber min={0} placeholder="20220211" />
        </Form.Item>

        <Form.Item
          label="Cantidad"
          name="quantity"
          rules={[{ required: true, message: 'Introducir cantidad' }]}
        >
          <InputNumber addonBefore={selectBefore} placeholder="0" />
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="description"
          rules={[{ required: true }]}
        >
          <TextArea rows={1} placeholder="Se realiza el ajuste por..." />
        </Form.Item>
        <Form.Item
          label="Fecha de la operación"
          name="operationDate"
          rules={[{ required: true, message: 'Introducir fecha de la operación' }]}
          initialValue={new Date()}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Guardar
            </Button>
            <Button htmlType="button" onClick={() => form.resetFields()}>
              Vaciar
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  )
}
