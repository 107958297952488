import { DeliveryOptionModel, DeliveryZoneModel } from '@wolf/entities'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { RouterOutput, trpc } from '../../../utils/trpc'
import { usePageOutletContext } from '../../../page-outlet-context'

type DeliveryParams = {
  deliveryZoneId?: DeliveryZoneModel['deliveryZoneId']
  deliveryOptionIds?: Array<DeliveryOptionModel['deliveryOptionId']>
}

type DeliveryParamsResponse = {
  deliveryParams: DeliveryParams
  deliveryZones: RouterOutput['logistics']['getDeliveryZonesWithOptions']
  changeDeliveryParams: (params: DeliveryParams) => void
  getDeliveryOptionsFromZone: (deliveryZoneId?: number) => RouterOutput['logistics']['getDeliveryZonesWithOptions'][number]['deliveryOptions']
}

export const URL_DELIVERY_PARAMS = {
  DELIVERY_ZONE_ID: 'deliveryZoneId',
  DELIVERY_OPTION_IDS: 'deliveryOptionIds'
} as const

export const useUrlDeliveryParams = (): DeliveryParamsResponse => {
  const [searchParams, setSearchParams] = useSearchParams()

  const { sourceFilter } = usePageOutletContext()

  const changeDeliveryParams = ({ deliveryOptionIds, deliveryZoneId }: DeliveryParams) => {

    if (deliveryZoneId) {
      searchParams.set(URL_DELIVERY_PARAMS.DELIVERY_ZONE_ID, deliveryZoneId.toString())
    } else {
      searchParams.delete(URL_DELIVERY_PARAMS.DELIVERY_ZONE_ID)
    }

    if (deliveryZoneId && deliveryOptionIds && deliveryOptionIds.length > 0) {
      searchParams.set(URL_DELIVERY_PARAMS.DELIVERY_OPTION_IDS, deliveryOptionIds.toString())
    } else {
      searchParams.delete(URL_DELIVERY_PARAMS.DELIVERY_OPTION_IDS)
    }

    return setSearchParams(searchParams)
  }

  const deliveryZoneId = searchParams.get(URL_DELIVERY_PARAMS.DELIVERY_ZONE_ID)
    ? Number(searchParams.get(URL_DELIVERY_PARAMS.DELIVERY_ZONE_ID))
    : undefined

  const deliveryOptionIds = searchParams.get(URL_DELIVERY_PARAMS.DELIVERY_OPTION_IDS)?.split(',').map((optionId) => Number(optionId))

  // Check params, if we have delivery options but not delivery zone, we should clean all the filters
  useEffect(() => {
    if (deliveryOptionIds?.length && !deliveryZoneId) {
      changeDeliveryParams({})
    }
  }, [searchParams])

  const { data: deliveryZones = [] } = trpc.logistics.getDeliveryZonesWithOptions.useQuery({
    sourceId: sourceFilter.serialId
  }, {
    staleTime: 1000 * 60 * 1
  })

  const getDeliveryOptionsFromZone = (deliveryZoneId?: number) => {
    if (!deliveryZoneId) {
      return []
    }

    const activeZone = deliveryZones.find((zone) => zone.deliveryZoneId === deliveryZoneId)

    if (!activeZone) {
      return []
    }

    return activeZone.deliveryOptions
  }

  return {
    deliveryParams: {
      deliveryZoneId,
      deliveryOptionIds
    },
    changeDeliveryParams,
    deliveryZones,
    getDeliveryOptionsFromZone
  }

}
