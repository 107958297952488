import React, { useEffect } from 'react'
import { Select, Space } from 'antd'
import { trpc } from '../utils/trpc'
import type { Models } from '@wolf/api'
import { useUrlGlobalFilterParams } from '../useUrlGlobalFilterParams'
import { useLocation } from 'react-router-dom'
import { format } from '@wolf/dates'

type Props = {
  salesGroupFilter: Models.SalesGroupFilterType
  setSalesPlanFilter: React.Dispatch<React.SetStateAction<Models.SalesGroupFilterType>>
}

export const SalesGroupPicker: React.FC<Props> = ({
  salesGroupFilter,
  setSalesPlanFilter,
}) => {
  const { changeSalesGroupParams, salesGroupFilterParams, sourceFilterParams } = useUrlGlobalFilterParams()

  const salesGroupQuery = trpc.salesGroups.getSalesGroups.useQuery(sourceFilterParams.serialId ?? -1, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 6 // 5 minutes
  }).data

  const location = useLocation()

  useEffect(() => {
    if (salesGroupFilter.salesGroupId !== -1) {
      // Set url params from global filter
      if (
        !salesGroupFilterParams.salesGroupId ||
        !salesGroupFilterParams.fromDate ||
        !salesGroupFilterParams.toDate
      ) {
        return changeSalesGroupParams({
          salesGroupId: salesGroupFilter.salesGroupId,
          replace: true,
        })
      }
      // Go back to salesGroup previous global filter
      if (
        salesGroupFilterParams.salesGroupId !== salesGroupFilter.salesGroupId
      ) {
        return setSalesPlanFilter({
          salesGroupId: salesGroupFilterParams.salesGroupId,
          fromDate: salesGroupFilterParams.fromDate,
          toDate: salesGroupFilterParams.toDate,
        })
      }
    }
  }, [location])

  return (
    <>
      Semana Wetaca:{' '}
      <Space>
        <Select
          value={salesGroupFilter.salesGroupId}
          size="small"
          onChange={(value) => {
            const salesGroup = salesGroupQuery?.find(
              (sg) => sg.salesGroupId === value
            )
            setSalesPlanFilter({
              salesGroupId: value,
              fromDate: salesGroup?.fromDate || new Date(),
              toDate: salesGroup?.toDate || new Date(),
            })
            changeSalesGroupParams({ salesGroupId: Number(value) })
          }}
          style={{ minWidth: '5.5rem' }}
          showSearch
          options={
            salesGroupQuery &&
            salesGroupQuery.map((salesGroup) => {
              return {
                label: `${salesGroup.salesGroupId} -
                ${format(salesGroup.fromDate, 'dd/MM/yyyy')} -> ${format(salesGroup.toDate, 'dd/MM/yyyy')}`,
                value: salesGroup.salesGroupId,
              }
            })
          }
        />
      </Space>
    </>
  )
}
