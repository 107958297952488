import { SalesGroupRoute } from '@wolf/entities'
import React from 'react'
import { useSearchParams } from 'react-router-dom'

type RouteParams = {
  route?: SalesGroupRoute['route']
  day?: SalesGroupRoute['day']
}

type RouteParamsResponse = {
  routeParams: RouteParams
  changeParams: (params: RouteParams) => void
}

const URL_ROUTE_PARAMS = {
  ROUTE_NAME: 'route',
  ROUTE_DAY: 'day'
} as const

export const useUrlRouteParams = (): RouteParamsResponse => {
  const [searchParams, setSearchParams] = useSearchParams()

  const changeParams = ({ route, day }: RouteParams) => {

    if (route) {
      searchParams.set(URL_ROUTE_PARAMS.ROUTE_NAME, route)
    } else {
      searchParams.delete(URL_ROUTE_PARAMS.ROUTE_NAME)
    }

    if (route && day !== undefined) {
      searchParams.set(URL_ROUTE_PARAMS.ROUTE_DAY, day.toString())
    } else {
      searchParams.delete(URL_ROUTE_PARAMS.ROUTE_DAY)
    }

    return setSearchParams(searchParams)
  }

  const route = searchParams.get(URL_ROUTE_PARAMS.ROUTE_NAME)
    ? searchParams.get(URL_ROUTE_PARAMS.ROUTE_NAME)?.toString()
    : undefined

  const day = searchParams.get(URL_ROUTE_PARAMS.ROUTE_DAY)
    ? Number(searchParams.get(URL_ROUTE_PARAMS.ROUTE_DAY))
    : undefined

  // Check params, if we have only one of props, we should clean the filter
  React.useEffect(() => {
    if (!route && day === undefined || day === undefined && route) {
      changeParams({})
    }
  }, [searchParams])

  return {
    routeParams: {
      route,
      day
    },
    changeParams
  }
}
