import { SalesGroupRoute } from '@wolf/entities'
import { useSearchParams } from 'react-router-dom'

type RouteParams = {
  day?: number | null
  routes?: Array<Pick<SalesGroupRoute, 'day' | 'route'>> | null
}

type RouteParamsResponse = {
  routeParams: RouteParams
  changeRouteParams: (params: RouteParams) => void
}

export const URL_ROUTES_PARAMS = {
  WITHOUT_ROUTE: 'withoutRoute',
  DAY: 'day',
  ROUTES: 'routes'
} as const

export const useUrlRouteParams = (): RouteParamsResponse => {
  const [searchParams, setSearchParams] = useSearchParams()

  const changeRouteParams = ({ day, routes }: RouteParams) => {
    if (routes) {
      searchParams.set(URL_ROUTES_PARAMS.ROUTES, routes.map((route) => `${route.route}-${route.day}`).toString())
    } else {
      searchParams.delete(URL_ROUTES_PARAMS.ROUTES)
    }

    if (day !== undefined && day !== null) {
      searchParams.delete(URL_ROUTES_PARAMS.WITHOUT_ROUTE)
      searchParams.set(URL_ROUTES_PARAMS.DAY, day.toString())
    } else {
      searchParams.delete(URL_ROUTES_PARAMS.DAY)

      day === null && routes === null
        ? searchParams.set(URL_ROUTES_PARAMS.WITHOUT_ROUTE, 'true')
        : searchParams.delete(URL_ROUTES_PARAMS.WITHOUT_ROUTE)
    }

    return setSearchParams(searchParams)
  }

  const day = searchParams.get(URL_ROUTES_PARAMS.DAY)
    ? Number(searchParams.get(URL_ROUTES_PARAMS.DAY))
    : undefined
  const routes = searchParams.get(URL_ROUTES_PARAMS.ROUTES)?.split(',')
  const withoutRoute = searchParams.get(URL_ROUTES_PARAMS.WITHOUT_ROUTE)

  if (withoutRoute && day === undefined) {
    return {
      routeParams: {
        day: null,
        routes: null
      },
      changeRouteParams
    }
  }

  return {
    routeParams: {
      day,
      routes: routes
        ? routes.flatMap((route) => {
          const matching = route.match(/^([\w\d-]*)-(\d)$/)

          if (!matching) {
            return []
          }

          const [_, routeName, day] = matching

          return [{
            route: routeName,
            day: Number(day),
          }]
        })
        : undefined
    },
    changeRouteParams
  }

}
