import { BoxModel, DeliveryGroupModel } from '@wolf/entities'
import { useSearchParams } from 'react-router-dom'

type OtherParams = {
  shouldChangeLabel?: BoxModel['shouldChangeLabel']
  isManagedDelivery?: DeliveryGroupModel['metadata']['isManagedDelivery']
}

type OtherParamsResponse = {
  otherParams: OtherParams
  changeOtherParams: (params: OtherParams) => void
}

export const URL_OTHER_PARAMS = {
  SHOULD_CHANGE_LABEL: 'shouldChangeLabel',
  IS_MANAGED_DELIVERY: 'isManagedDelivery'
} as const

const getBooleanOrUndefined = (value: string | null): boolean | undefined => {
  if (value === null) return undefined
  return value === 'true'
}

export const useUrlOtherParams = (): OtherParamsResponse => {
  const [searchParams, setSearchParams] = useSearchParams()

  const changeOtherParams = ({ shouldChangeLabel, isManagedDelivery }: OtherParams) => {

    if (shouldChangeLabel) {
      searchParams.set(URL_OTHER_PARAMS.SHOULD_CHANGE_LABEL, `${true}`)
    } else {
      searchParams.delete(URL_OTHER_PARAMS.SHOULD_CHANGE_LABEL)
    }

    if (typeof isManagedDelivery === 'boolean') {
      searchParams.set(URL_OTHER_PARAMS.IS_MANAGED_DELIVERY, `${isManagedDelivery}`)
    } else {
      searchParams.delete(URL_OTHER_PARAMS.IS_MANAGED_DELIVERY)
    }

    return setSearchParams(searchParams)
  }

  const currentShouldChangeLabelValue = Boolean(searchParams.get(URL_OTHER_PARAMS.SHOULD_CHANGE_LABEL)) || undefined

  const currentIsDeliveryManagedValue = getBooleanOrUndefined(searchParams.get(URL_OTHER_PARAMS.IS_MANAGED_DELIVERY))

  return {
    otherParams: {
      shouldChangeLabel: currentShouldChangeLabelValue,
      isManagedDelivery: currentIsDeliveryManagedValue
    },
    changeOtherParams: changeOtherParams
  }

}
