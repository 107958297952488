import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
  PauseOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import { SyncRoute, SyncStatus } from '@wolf/entities'
import {
  Button,
  Card,
  Form,
  Popover,
  Select,
  Space,
  Table,
  TableColumnsType,
} from 'antd'
import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { usePageOutletContext } from '../../../page-outlet-context'
import { trpc } from '../../../utils/trpc'
import { addDays, format, getDay, isBefore, isAfter, isSameDay, subDays } from '@wolf/dates'
import { RangePicker } from '../../../components/DatePicker'
import { dayNames } from '../../../utils/day-names'

type FormValues = {
  days: Array<Date>
  routes: Array<string>
}

type IntervalDays = {
  fromDay: number
  toDay: number
}

export const SyncRoutes: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { notificationInstance, sourceFilter, salesGroupFilter } = usePageOutletContext()
  const [form] = Form.useForm()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [intervalDays, setIntervalDays] = useState<IntervalDays | undefined>(undefined)

  const salesGroupRoutes = trpc.salesGroups.getSalesGroup.useQuery(
    {
      salesGroupId: salesGroupFilter.salesGroupId,
      sourceId: sourceFilter.serialId
    },
    { enabled: salesGroupFilter.salesGroupId !== -1 }
  )

  const getSyncJobsQuery = trpc.syncJobs.getSyncJobs.useQuery(
    {
      pagination: {
        pageSize,
        currentPage,
      },
      sourceId: sourceFilter.serialId,
    },
    {
      refetchInterval: 5000,
      onSuccess: (data) => {
        salesGroupRoutes.refetch()
        data.pendingSyncJob ? setIsLoading(true) : setIsLoading(false)
      },
      onError: () => {
        setIsLoading(false)
      },
    }
  )

  const createSyncJobMutation = trpc.syncJobs.createSyncJob.useMutation({
    onError: (error) => {
      setIsLoading(false)
      showError(error.message)
      getSyncJobsQuery.refetch()
    },
    onSuccess: () => {
      setIsLoading(true)
      getSyncJobsQuery.refetch()
    },
  })

  const showError = (errorMessage: string) =>
    notificationInstance.error({
      message: 'Sincronización fallida',
      description: errorMessage,
    })

  const onFinish = async (values: FormValues) => {
    setIsLoading(true)

    const [fromDate, toDate] = values.days

    createSyncJobMutation.mutate({
      fromDate,
      toDate,
      sourceId: sourceFilter.serialId,
      type: 'SYNC_ORDERS',
      routes: values.routes ? values.routes.map((route) => JSON.parse(route)) : []
    })
  }

  type DataType = {
    id: string
    status: SyncStatus
    params: {
      fromDate: Date
      toDate: Date
    }
    validUntil: Date
    userId: string
    dateCreated: Date
  }

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Usuario',
      dataIndex: 'userId',
      key: 'userId',
    },
    {
      title: 'Fecha',
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      render: (date: string) => format(new Date(date), 'HH:mm:ss dd/MM/yyyy'),
    },
    {
      title: 'Desde',
      dataIndex: ['params', 'fromDate'],
      key: 'fromDate',
      render: (date: string) => format(new Date(date), 'dd/MM/yyyy'),
    },
    {
      title: 'Hasta',
      dataIndex: ['params', 'toDate'],
      key: 'toDate',
      render: (date: string) => format(new Date(date), 'dd/MM/yyyy'),
    },
    {
      title: 'Tipo',
      dataIndex: ['params', 'type'],
      key: 'type',
      render: (type: string | null) => {
        if (type === 'SYNC_ORDERS') {
          return 'Sincronización'
        } else if (type === 'SYNC_NAMES') {
          return 'Búsqueda'
        } else {
          return ''
        }
      },
    },
    {
      title: 'Rutas',
      dataIndex: ['params', 'routes'],
      key: 'routes',
      render: (routes: Array<SyncRoute> | null) => {
        if (!routes || routes.length === 0) {
          return 'Todas'
        } else {
          return (
            <Popover placement='bottomLeft' content={routes.map((route) => (
              `${route.route} - ${dayNames[route.day]}`
            )).join(' | ')}>
              {routes.map((route, index) => {
                if(index<3) return `${route.route} - ${dayNames[route.day]}`
              }).join(' | ') + (routes.length > 3 ? '...' : '')}
            </Popover>
          )
        }
      },
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (status: SyncStatus) => {
        switch (status) {
          case 'PENDING':
            return (
              <Popover placement="bottom" content={'PENDIENTE'}>
                <PauseOutlined />
              </Popover>
            )
          case 'IN_PROGRESS':
            return (
              <Popover placement="bottom" content={'EN PROGRESO'}>
                <SyncOutlined spin />
              </Popover>
            )
          case 'DONE':
            return (
              <Popover placement="bottom" content={'FINALIZADA'}>
                <CheckOutlined />
              </Popover>
            )
          case 'ERROR':
            return (
              <Popover placement="bottom" content={'ERRONEA'}>
                <CloseOutlined />
              </Popover>
            )
        }
      },
    },
  ]

  const onPaginationChange = (current: number, pageSize: number) => {
    setCurrentPage(current)
    setPageSize(pageSize)
  }

  //Return default day for a given salesgroup, based on the current day of the week.
  const defaultDay = () => {
    const dayWeek = getDay(new Date())

    const daysToAdd =
      dayWeek -
      getDay(salesGroupFilter.fromDate) +
      (dayWeek < getDay(salesGroupFilter.fromDate) ? 7 : 0)

    return addDays(salesGroupFilter.fromDate, daysToAdd)
  }

  useEffect(() => {
    const defaultFirstDay = defaultDay()
    const defaultLastDay = subDays(salesGroupFilter.toDate, 1)

    form.setFieldsValue({
      days: [defaultFirstDay, defaultLastDay],
    })

    setIntervalDays(({
      fromDay: getDay(defaultFirstDay),
      toDay: getDay(defaultLastDay),
    }))
  }, [salesGroupFilter])

  if (salesGroupFilter.salesGroupId === -1 || salesGroupRoutes.isLoading) return <LoadingOutlined />

  return (
    <>
      <Helmet title="Sincronizar Rutas - Wolf" />

      <Card title="Sincronizar con OptimoRoute" className="mb-4">
        <Form layout="inline" onFinish={onFinish} form={form}>
          <Form.Item
            label="Fecha"
            name="days"
            rules={[{ required: true, message: 'Introducir fecha' }]}
          >
            <RangePicker
              placeholder={['Fecha inicio', 'Fecha final']}
              onChange={(current) => setIntervalDays({
                fromDay: current && current[0] ? getDay(current[0]) : 0,
                toDay: current && current[1] ? getDay(current[1]) : 6,
              })}
              disabledDate={(current) => {
                return isBefore(current, salesGroupFilter.fromDate) || isAfter(current, salesGroupFilter.toDate) || isSameDay(current, salesGroupFilter.toDate)
              }}
            />
          </Form.Item>

          <Form.Item
            label="Rutas"
            name="routes"
          >
            <div>
              <Select
                placeholder='Todas'
                mode={'multiple'}
                maxTagCount={'responsive'}
                className='block self-center h-8 mr-2 min-w-[500px]'
                showSearch
                allowClear
                options={
                  salesGroupRoutes.data && salesGroupRoutes.data.routes.length > 0 && intervalDays
                    ? salesGroupRoutes.data.routes
                      .filter(route => {
                        return intervalDays.fromDay <= intervalDays.toDay
                          ? route.day >= intervalDays.fromDay && route.day <= intervalDays.toDay
                          : route.day >= intervalDays.fromDay || route.day <= intervalDays.toDay
                      })
                      .sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)))
                      .map((route) => ({
                        label: `${route.route} - ${dayNames[route.day]}`,
                        value: JSON.stringify(route)
                      }))
                    : []
                }
                onChange={(selectedValues) => {
                  form.setFieldsValue({ routes: selectedValues })
                }}
              />
              <Button
                type='link'
                className='text-left p-0'
                disabled={isLoading}
                onClick={() => {
                  createSyncJobMutation.mutate({
                    fromDate: salesGroupFilter.fromDate,
                    toDate: subDays(salesGroupFilter.toDate, 1),
                    sourceId: sourceFilter.serialId,
                    type: 'SYNC_NAMES',
                    routes: []
                  })
                }}
              >
                Buscar nuevas rutas
              </Button>
            </div>
          </Form.Item>

          <Form.Item>
            <Space>
              <Button
                className="self-center mb-4 ml-auto"
                type="primary"
                htmlType="submit"
                disabled={isLoading}
                icon={isLoading ? <LoadingOutlined /> : <SyncOutlined />}
              >
                Sincronizar
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>

      <Card title="Últimas sincronizaciones">
        <Table
          className="border-solid border border-slate-200 rounded-lg mb-4"
          columns={columns}
          dataSource={getSyncJobsQuery.data?.listSyncJob}
          loading={getSyncJobsQuery.isLoading}
          rowKey={(sync) => sync.id}
          pagination={{
            className: 'pr-4',
            total: getSyncJobsQuery.data?.total,
            pageSize,
            current: currentPage,
            showSizeChanger: true,
            onChange: onPaginationChange,
          }}
        />
      </Card>
    </>
  )
}
