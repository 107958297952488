import { LoadingOutlined } from '@ant-design/icons'
import { Helmet } from 'react-helmet-async'
import { Title } from '../../components/Title'
import { usePageOutletContext } from '../../page-outlet-context'
import { trpc } from '../../utils/trpc'
import { Expedition } from './components/Expedition'
import { ExpeditionSelect } from './components/ExpeditionSelect'
import { useUrlRouteParams } from './hooks/useUrlRouteParams'

export const Expeditions: React.FC = () => {
  const { salesGroupFilter, sourceFilter } = usePageOutletContext()
  const { routeParams } = useUrlRouteParams()

  const salesGroupRoutes = trpc.salesGroups.getSalesGroup.useQuery(
    {
      salesGroupId: salesGroupFilter.salesGroupId,
      sourceId: sourceFilter.serialId
    },
    { enabled: salesGroupFilter.salesGroupId !== -1 }
  )

  if (salesGroupRoutes.isLoading || !salesGroupRoutes.data) {
    return <LoadingOutlined />
  }

  return (
    <>
      <Helmet title="Expediciones - Wolf" />

      <Title>Expediciones</Title>

      {routeParams.route && routeParams.day !== undefined ? (
        <Expedition
          activeRoute={{ route: routeParams.route, day: routeParams.day }}
          routes={salesGroupRoutes.data.routes}
        />
      ) : (
        <div className="flex flex-wrap mt-4 self-center">
          <ExpeditionSelect routes={salesGroupRoutes.data.routes} />
        </div>
      )}
    </>
  )
}
