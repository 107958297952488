import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isProductPicked, PickingReferenceResponse, CustomerPickingReferenceResponse } from '../picking.utils'
import Search from 'antd/es/input/Search'
import { RouterOutput, trpc } from '../../../utils/trpc'
import { isBox, isCustomer, objectFromCode, printCustomerLabel, productFromCode, productToCode } from '@wolf/barcodes'
import { InputRef } from 'antd'
import { usePageOutletContext } from '../../../page-outlet-context'

type Props = {
  box: RouterOutput['boxes']['getBoxByIdWithAssemblies']
  onCloseNotification?: () => void
}

export const BoxPickingInput = React.forwardRef<InputRef, Props>(({ box, onCloseNotification }, refSearch) => {

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined)
  const [repeatedPickIsEnabled, setRepeatedPickIsEnabled] = useState<boolean>(true)

  const navigate = useNavigate()
  const { notificationInstance, sourceFilter, workspace } = usePageOutletContext()
  const utils = trpc.useContext()

  const disableRepeatedPick = () => {
    // This condition will only affect when the tapper that is being picked is repeated and has customerLabel
    // The customer label won´t be printed for a defined time (now 300 ms) since the last product picking
    setRepeatedPickIsEnabled(false)
    setTimeout(() => {
      setRepeatedPickIsEnabled(true)
    }, 300)
  }

  const createPickingMutation = trpc.boxes.createPicking.useMutation({
    onSuccess: (updatedBox, input) => {
      utils.boxes.getBoxByIdWithAssemblies.setData({
        id: Number(updatedBox.serialId),
        sourceId: sourceFilter.serialId
      },
      {
        ...box,
        ...updatedBox
      })

      const lastPick = updatedBox.pickingStateHistory[updatedBox.pickingStateHistory.length - 1]
      const lastOrderIdPicked = 'orderId' in lastPick ? lastPick.orderId : -1

      const pickedProductId = input.productId
      const pickedProduct = updatedBox.picking.find(picking => picking.productId === pickedProductId && picking.orderId === lastOrderIdPicked)

      const tapperHasCustomerLabel = !!pickedProduct && !!pickedProduct.customerLabel

      if (tapperHasCustomerLabel) {

        // We should take the same offset for the customer label than the product picking
        const index = productFromCode(pickedProduct.picking[pickedProduct.picking.length - 1]).index

        printCustomerLabel({
          orderId: pickedProduct.orderId ?? -1,
          customerLabel: pickedProduct.customerLabel ?? '',
          sourceId: sourceFilter.serialId,
          workspaceId: workspace.serialId,
          deliveryGroupId: updatedBox.deliveryGroupId,
          boxId: updatedBox.serialId,
          productId: pickedProduct.productId,
          index
        })
      }

    },
    onError: (_, input) => {
      const product = box.picking.find(picking => picking.productId === input.productId)

      if (product) {
        return showError(`No se ha podido realizar el picking del producto ${input.productId}`)
      }
      showError(`No se ha podido realizar el picking del producto ${input.productId}. El producto no pertenece a la caja`)
    },
  })

  const createCustomerPickingMutation = trpc.boxes.createCustomerPicking.useMutation({
    onSuccess: (updatedBox) => {
      utils.boxes.getBoxByIdWithAssemblies.setData({
        id: Number(updatedBox.serialId),
        sourceId: sourceFilter.serialId
      }, { ...box, ...updatedBox })
    },
    onError: (_, input) => {
      const product = box.picking.find(picking => picking.productId === input.productId)

      if (product) {
        return showError(`No se ha podido realizar el picking de la etiqueta de cliente del producto ${input.productId}`)
      }
      showError(`No se ha podido realizar el picking de la etiqueta de cliente del producto ${input.productId}. El producto no pertenece a la caja`)
    },
  })

  const showError = (description: string) => {
    notificationInstance.error({
      className: 'w-full bg-red-200',
      onClose: onCloseNotification,
      description: <span className='text-lg'>{description}</span>,
      message: 'Error',
      duration: 0
    })
  }

  const onSearch = () => {
    if (!searchValue) {
      return
    }

    try {
      const params = objectFromCode(searchValue)

      if (isBox(params)) {
        setSearchValue(undefined)
        return navigate(`/picking/${params.boxId}`)
      }

      const isCustomerLabel = isCustomer(params)

      if (isCustomerLabel) {

        const customerLabel = box.picking.find(pick => pick.productId === params.productId && !!pick.customerLabel)?.customerLabel

        const customerPickingParams: CustomerPickingReferenceResponse = {
          sourceId: sourceFilter.serialId,
          workspaceId: workspace.serialId,
          deliveryGroupId: params.deliveryGroupId,
          boxId: params.boxId,
          productId: params.productId,
          index: params.index,
          customerLabel: customerLabel ?? ''
        }
        customerPicking(customerPickingParams, searchValue)
      } else {
        const pickingParams = {
          sourceId: sourceFilter.serialId,
          workspaceId: workspace.serialId,
          lotId: params.date,
          offset: params.index,
          ...params,
        }
        picking(pickingParams)
      }

    } catch (error) {
      showError(`Referencia incorrecta. El formato ${searchValue} no es correcto.`)
    }
    setSearchValue(undefined)
  }

  const picking = async (params: PickingReferenceResponse) => {

    const productReference = {
      sourceId: params.sourceId,
      workspaceId: params.workspaceId,
      productId: params.productId,
      date: params.lotId,
      index: params.offset
    }

    const pickReference = productToCode(productReference)

    // Tupper is packed
    const repeated = box.picking.find(({ picking }) => picking.includes(pickReference))

    if (repeated) {
      // In this case we should re-print the customer label
      if (repeated.customerLabel && repeatedPickIsEnabled) {
        disableRepeatedPick()
        printCustomerLabel({
          orderId: repeated.orderId ?? -1,
          customerLabel: repeated.customerLabel,
          sourceId: params.sourceId,
          workspaceId: params.workspaceId,
          deliveryGroupId: box.deliveryGroupId,
          boxId: box.serialId,
          productId: repeated.productId,
          index: params.offset
        })
      }
      return
    }

    // Product quantity already packed
    const picked = isProductPicked(Number(params.productId), box)
    if (picked) {
      showError(`No se ha podido realizar el picking del producto ${params.productId}. El producto ya ha sido montado.`)
      return
    }

    // Product is not in box
    const pickingCandidate = box.picking
      .filter(({ productId }) => productId === params.productId)

    if (pickingCandidate.length === 0) {
      showError(`No se ha podido realizar el picking del producto ${params.productId}. El producto no pertenece a la caja.`)
      return
    }

    disableRepeatedPick()

    createPickingMutation.mutate({ ...params, boxId: box.serialId, salesGroupId: box.salesGroupId, deliveryGroupId: box.deliveryGroupId })
  }

  const customerPicking = async (params: CustomerPickingReferenceResponse, code: string) => {

    const customerPickings = box.picking.flatMap(({ customersPicking }) => customersPicking ? customersPicking : [])
    const customerPickingCandidate = customerPickings.find(customerPicking => code === customerPicking.code)

    // Customer label is not expected in box
    if (!customerPickingCandidate) {
      showError(`No se ha podido realizar el picking de la etiqueta de cliente ${params.customerLabel} para el producto ${params.productId}. Este cliente no tiene este producto asociado en la caja.`)
      return
    }

    // Customer label is picked
    const repeated = customerPickingCandidate.picked
    if (repeated) {
      return
    }

    createCustomerPickingMutation.mutate({ ...params, boxId: box.serialId })
  }

  return (
    <div className='flex-column mb-12'>
      <p>Referencia:</p>
      <Search
        ref={refSearch}
        placeholder={'Caja/Picking'}
        allowClear
        value={searchValue}
        onSearch={onSearch}
        onChange={(event) => {
          setSearchValue(event.target.value)
        }}
        autoFocus
        enterButton='+'
      />
    </div>
  )
})
