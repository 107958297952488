import { BarcodeOutlined, CheckOutlined, CloseOutlined, DownloadOutlined, MailOutlined } from '@ant-design/icons'
import { Models } from '@wolf/api'
import { format } from '@wolf/dates'
import { SalesGroupRoute } from '@wolf/entities'
import { Button, Checkbox } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { Title } from '../../../components/Title'
import { PackingListTemplate } from '../../../components/packing-list/PackingListTemplate'
import { CommonTableProps } from '../../../components/table-props'
import { usePageOutletContext } from '../../../page-outlet-context'
import { dayNames } from '../../../utils/day-names'
import { RouterOutput, trpc } from '../../../utils/trpc'
import { RoutesFilter } from '../../boxes/components/filters/RoutesFilter'
import { useUrlRouteParams } from '../../boxes/hooks/useUrlRouteParams'
import { useUrlRouteRequirementsParams } from './hooks/useUrlRouteRequirementsParams'

type RouteBox = RouterOutput['salesGroups']['getSalesGroupRoutesSummary'][0]

export const RoutesSummary: React.FC = () => {

  const { salesGroupFilter, sourceFilter, notificationInstance } = usePageOutletContext()
  const { requirementsParams, changeParams } = useUrlRouteRequirementsParams()
  const { routeParams } = useUrlRouteParams()
  const navigate = useNavigate()

  const [routeToPrint, setRouteToPrint] = useState<SalesGroupRoute | undefined>(undefined)
  const [printPackingList, setPrintPackingList] = useState<boolean>(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<React.Key>>([])

  const validRouteParams = (routeParams.day !== undefined && routeParams.day !== null && routeParams.day > -1) || (routeParams.routes !== undefined && routeParams.routes !== null)

  const getSalesGroupRoutesQuery = trpc.salesGroups.getSalesGroupRoutesSummary.useQuery(
    {
      sourceId: sourceFilter.serialId,
      requirementsFilter: requirementsParams || [],
      salesGroupFilter,
      routesFilter: routeParams
    },
    {
      enabled: salesGroupFilter.salesGroupId !== -1 && validRouteParams,
      refetchOnWindowFocus: false,
      onSuccess: () => setPrintPackingList(false)
    }
  )

  const getRouteBoxes = trpc.boxes.getRouteBoxes.useQuery(
    {
      sourceId: sourceFilter.serialId,
      salesGroupId: salesGroupFilter.salesGroupId,
      route: routeToPrint
    },
    { enabled: routeToPrint !== undefined }
  )

  const sendOrderScheduledEvent = trpc.logistics.sendOrderScheduledEvent.useMutation()

  const formatRowKeysToRoutes = (routeKeys: Array<React.Key>) => routeKeys.map(routeKey => JSON.parse(routeKey.toString()))

  const getFormattedRoutes = (routes: RouterOutput['logistics']['sendOrderScheduledEvent']['routesWithDeliveries']) => {
    return routes.map(({ route, day }, index) => {
      if (index === 0) {
        return `${route}-${dayNames[day]}`
      }
      return ` ${route}-${dayNames[day]}`
    }).toString()
  }

  const onSendOrderScheduled = (routes: Array<RouteBox['route']>) => {
    sendOrderScheduledEvent.mutate({
      routes,
      salesGroupId: salesGroupFilter.salesGroupId,
      sourceId: sourceFilter.serialId
    }, {
      onSuccess: (data) => {

        const { routesWithDeliveries, routesWithoutDeliveries } = data

        showSuccess(getFormattedRoutes(routesWithDeliveries))

        if (routesWithoutDeliveries.length > 0) {
          showError(getFormattedRoutes(routesWithoutDeliveries))
        }

        setSelectedRowKeys([])
        getSalesGroupRoutesQuery.refetch()
        getRouteBoxes.refetch()
      },
      onError: () => {
        showError()
        setSelectedRowKeys([])
      }
    })
  }

  const showError = (routes?: string) =>
    notificationInstance.error({
      message: 'Error al envíar emails',
      description: <span>No se han encontrado deliveries a los que enviar emails para la(s) ruta(s) {routes && <strong>: {routes}</strong>}</span>,
      duration: 0
    })

  const showSuccess = (routes: string) =>
    notificationInstance.success({
      message: 'Emails enviados',
      description: <span>Se han enviado los emails de acotación para la(s) ruta(s): <strong>{routes}</strong></span>,
      duration: 3
    })

  const print = () => {
    // Print in the next react loop
    setTimeout(() => (setPrintPackingList(true)))
  }

  const columns: ColumnsType<RouteBox> = [
    {
      dataIndex: 'route',
      title: 'Ruta',
      key: 'route',
      render: (route: RouteBox['route']) => `${route.route} - ${dayNames[route.day]}`
    },
    {
      dataIndex: ['data', 'hasIncompletedBoxes'],
      title: 'Cajas mal montadas',
      key: 'hasIncompletedBoxes',
      render: (hasIncompletedBoxes) => !hasIncompletedBoxes ? <CheckIcon /> : <CloseIcon />
    },
    {
      dataIndex: ['data', 'hasBoxesOnAnotherRoute'],
      title: 'Cajas en otra ruta',
      key: 'hasBoxesOnAnotherRoute',
      render: (hasBoxesOnAnotherRoute) => !hasBoxesOnAnotherRoute ? <CheckIcon /> : <CloseIcon />
    },
    {
      dataIndex: ['data', 'hasBoxesFromAnotherRoute'],
      title: 'Cajas de otra ruta',
      key: 'hasBoxesFromAnotherRoute',
      render: (hasBoxesFromAnotherRoute) => !hasBoxesFromAnotherRoute ? <CheckIcon /> : <CloseIcon />
    },
    {
      dataIndex: ['data', 'isCompleted'],
      title: 'Completa',
      key: 'isCompleted',
      render: (isCompleted) => isCompleted ? <CheckIcon /> : <CloseIcon />
    },
    {
      dataIndex: 'route',
      title: 'Email enviado',
      key: 'emailNotification',
      render: (route: RouteBox['route']) => route.emailNotification.length > 0 && <p className='my-0'>{format(route.emailNotification[route.emailNotification.length - 1].date, 'HH:mm dd/MM/yyyy')}</p>
    },
    {
      dataIndex: [],
      key: 'actions',
      render: (box: RouteBox) => {
        return (
          <>
            <Button
              icon={<BarcodeOutlined />}
              className={'ml-2'}
              onClick={() => {
                return navigate(`/expeditions?salesGroupId=${salesGroupFilter.salesGroupId}&sourceId=${sourceFilter.serialId}&route=${box.route.route}&day=${box.route.day}`)
              }}
            />
            <Button
              icon={<DownloadOutlined />}
              className={'ml-2'}
              onClick={() => {
                setRouteToPrint(box.route)
                print()
              }}
            />
            {
              box.route.notify && box.route.notify === true &&
              <Button
                icon={<MailOutlined />}
                disabled={sendOrderScheduledEvent.isLoading}
                className={'ml-2'}
                onClick={() => {
                  onSendOrderScheduled([box.route])
                }}
              />
            }
          </>
        )
      }
    }
  ]

  const requirementsOptions: Array<{ label: string; value: Models.RouteRequirementFilter }> = [
    { label: 'Con cajas mal montadas', value: 'withIncompletedBoxes' },
    { label: 'Con cajas en otra ruta', value: 'withBoxesOnAnotherRoute' },
    { label: 'Con cajas de otra ruta', value: 'withBoxesFromAnotherRoute' },
    { label: 'Incompletas', value: 'incompleted' }
  ]

  return (
    <>
      <Helmet
        title='Rutas - Wolf'
      />

      <Title>Resumen de Rutas</Title>

      <div className='flex flex-wrap mr-4 mb-2'>
        <div className='flex ml-1 mr-3 items-baseline'>
          <p className='mr-4'>Rutas:</p>
          <Checkbox.Group
            options={requirementsOptions}
            value={requirementsParams}
            onChange={(requirements) => {
              changeParams(requirements as Array<Models.RouteRequirementFilter>)
            }}
          />
          <Button
            type="primary"
            className='ml-2'
            onClick={() => changeParams(undefined)}
            disabled={!requirementsParams}
            size={'small'}
          >
            Reset
          </Button>
        </div>
      </div>
      <div className='flex'>
        <RoutesFilter showWithoutRoute={false} />
        {getSalesGroupRoutesQuery.data && (
          <Button
            className='self-center mb-4 mr-2 ml-auto'
            type="primary"
            loading={getSalesGroupRoutesQuery.isInitialLoading}
            icon={<MailOutlined />}
            disabled={selectedRowKeys.length === 0}
            onClick={() => onSendOrderScheduled(formatRowKeysToRoutes(selectedRowKeys))}
          >
            Enviar Correos
          </Button>
        )}
      </div>
      {(getSalesGroupRoutesQuery.data || validRouteParams) && (
        <Table
          {...CommonTableProps}
          className='border-solid border border-slate-200 rounded-lg mt-4 overflow-hidden'
          columns={columns}
          loading={getSalesGroupRoutesQuery.isLoading}
          rowSelection={{
            selectedRowKeys,
            onChange: setSelectedRowKeys,
            getCheckboxProps: (route: RouteBox) => ({
              disabled: !route.route.notify,
              ...!route.route.notify && { className: 'hidden' }
            })
          }}
          dataSource={getSalesGroupRoutesQuery.data?.sort((a, b) => a.route.route.localeCompare(b.route.route))}
          pagination={false}
          rowKey={({ route }) => JSON.stringify({ route: route.route, day: route.day })}
        />
      )}
      {printPackingList && routeToPrint && getRouteBoxes.data &&
        <PackingListTemplate
          route={routeToPrint}
          boxes={getRouteBoxes.data}
          onClose={() => {
            setPrintPackingList(false)
          }}
        />
      }
    </>
  )
}

const CheckIcon = () => {
  return <CheckOutlined className='bg-lime-500 rounded-lg p-0.5' />
}

const CloseIcon = () => {
  return <CloseOutlined className='bg-red-500 rounded-lg p-0.5' />
}
