import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import './index.css'
import { ConfigProvider } from 'antd'
import esES from 'antd/locale/es_ES'

// eslint-disable-next-line no-console
console.log('Welcome to the Trastaca 2.0 experience. In memoriam of Raúl.')

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <ConfigProvider locale={esES}>
      <App />
    </ConfigProvider>
  </StrictMode>,
)

export type { env } from './env'
