import React, { useEffect, useRef, useState } from 'react'
import { trpc } from '../../../utils/trpc'
import { productFromCode } from '@wolf/barcodes'
import Search from 'antd/es/input/Search'
import { opType } from '@wolf/entities'
import { Modal, Form, Button, Input, InputRef, Checkbox, FormInstance } from 'antd'
import { usePageOutletContext } from '../../../page-outlet-context'
import { useDefaultConfiguration } from '../../../useDefaultConfiguration'
import { StockAlert } from '../../../components/StockAlert'

type InputBarcodeProps = {
  onSuccess: () => void
}

const opTypeSum: opType = 'SUM'

export const InputStockBarcode: React.FC<InputBarcodeProps> = (props) => {
  const [codeValue, setCodeValue] = useState<string | undefined>(undefined)
  const [modalContent, setModalContent] = useState<React.ReactNode | null>(null)
  const [multipleStocks, setMultipleStocks] = useState<boolean>(true)

  const [form] = Form.useForm()

  const refSearch = useRef<InputRef>(null)

  const { notificationInstance, salesGroupFilter, sourceFilter, workspace } = usePageOutletContext()
  const defaultConfig = useDefaultConfiguration()

  const stockMutation = trpc.stockEntries.createStockEntry.useMutation({
    onError: () => {
      showError(`El código ${codeValue} ya ha sido introducido`)
    },
    onSuccess: (success) => {
      if (success.op === opTypeSum) {
        showSuccess(success.lotId, success.offset, success.quantity)
        props.onSuccess()
      }
    },
  })

  const showError = (errorMessage: string) =>
    notificationInstance.error({
      message: 'Código incorrecto',
      description: errorMessage,
    })

  const showSuccess = (lotId: number, offset: number, quantity: number) =>
    notificationInstance.success({
      message: 'Código correcto',
      description: `Identificador ${offset} del lote ${lotId} con cantidad ${quantity} añadido`,
      duration: 2,
    })

  const resetStates = () => {
    setModalContent(null)
    setCodeValue('')
    form.resetFields()
  }

  const handleFinish = (values: { quantity: number }) => {
    try {
      if (!salesGroupFilter.salesGroupId) {
        return
      }

      if (codeValue) {
        const product = productFromCode(codeValue)

        const stockEntry = {
          sourceId: 'sourceId' in product ? product.sourceId : sourceFilter.serialId,
          workspaceId: 'workspaceId' in product ? product.workspaceId : workspace.serialId,
          salesGroupId: 'salesGroupId' in product ? product.salesGroupId : salesGroupFilter.salesGroupId,
          productId: product.productId,
          lotId: product.date,
          offset: product.index,
          op: opTypeSum,
          quantity: Number(values.quantity)
        }

        stockMutation.mutate(stockEntry)
      }
    } catch (error) {
      showError(`${error}`)
    }
    resetStates()
  }

  const focusCodeInput = () => {
    if (refSearch.current) {
      refSearch.current.focus()
    }
  }

  useEffect(() => {
    if (setModalContent === null) {
      focusCodeInput()
    }
  }, [setModalContent])

  return (
    <div className="flex items-center mb-4">
      <p>Código de barras:</p>
      <Search
        className="flex-1 ml-4"
        placeholder="(90)03(20)01(251)01(92)202208(21)01(8008)1702232359(240)01"
        onSearch={() => {

          // If selected salesGroup is not the current one
          if (defaultConfig.isLoading === false && defaultConfig.defaultSalesGroup.salesGroupId !== salesGroupFilter.salesGroupId) {
            return setModalContent(<StockAlert
              title={<span>La semana seleccionada ({salesGroupFilter.salesGroupId}) <strong>NO</strong> es la actual</span>}
              text={<span>¿Estás seguro de que quieres dar de alta stocks para la semana <strong>{salesGroupFilter.salesGroupId}</strong>? La semana actual es la <strong>{defaultConfig.defaultSalesGroup.salesGroupId}</strong></span>}
              onConfirm={() => {
                multipleStocks
                  ? setModalContent(<MultipleStocksModalContent form={form} handleFinish={handleFinish}></MultipleStocksModalContent>)
                  : handleFinish({ quantity: 1 })
              }}
              onCancel={() => setModalContent(null)}
            />)
          }

          if (multipleStocks) {
            return setModalContent(<MultipleStocksModalContent form={form} handleFinish={handleFinish}></MultipleStocksModalContent>)
          }

          return handleFinish({ quantity: 1 })
        }}
        onChange={(event) => {
          setCodeValue(event.target.value)
        }}
        ref={refSearch}
        value={codeValue}
        enterButton="Guardar"
      />
      <Checkbox
        onChange={() => setMultipleStocks(!multipleStocks)}
        onClick={focusCodeInput}
        checked={multipleStocks}
        className='ml-4'
      >
        Stocks múltiples
      </Checkbox>
      <Modal title="Alta de Stock" destroyOnClose={true} open={modalContent !== null} onCancel={resetStates} footer={null}>
        {modalContent}
      </Modal>
    </div>
  )
}

type ContentProps = {
  form: FormInstance
  handleFinish: (values: { quantity: number }) => void
}
const MultipleStocksModalContent = ({ form, handleFinish }: ContentProps) => {

  const [showAlert, setShowAlert] = useState<boolean>(false)

  const refModal = useRef<InputRef>(null)

  const handleSubmit = (values: { quantity: number }) => {
    if (Number(values.quantity) > 10000) {
      return setShowAlert(true)
    }

    handleFinish(values)
  }

  useEffect(() => {
    if (refModal.current && !showAlert) {
      refModal.current.focus()
    }
  }, [refModal, showAlert])

  return (
    <>
      <p>¿Cuántos stocks se van a dar de alta?</p>
      <Form layout="inline" form={form} onFinish={handleSubmit}>
        <Form.Item
          initialValue={1}
          name="quantity"
          rules={[{ required: true, message: 'Introducir cantidad' }]}
        >
          <Input autoFocus ref={refModal} type='number' min={1} placeholder="1" disabled={showAlert} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={showAlert}>
            Guardar
          </Button>
        </Form.Item>
        { }
      </Form>
      {showAlert && <StockAlert
        title='Alta cantidad de stock'
        text={<span>¿Estás seguro de que quieres dar de alta <strong>{form.getFieldValue('quantity')}</strong> stocks?</span>}
        onConfirm={() => handleFinish({ quantity: form.getFieldValue('quantity') })}
        onCancel={() => {
          setShowAlert(false)
          form.setFieldValue('quantity', 1)
        }}
      />}
    </>
  )
}
