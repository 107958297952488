import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Page } from './components/Page'
import { RoutesMap } from './routes-map'

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Page />}>
          {
            Object.values(RoutesMap.protected).map((route) => (
              <Route {...route.routeProps} key={route.routeProps.path} />
            ))
          }
        </Route>

        {
          Object.values(RoutesMap.public).map((route) => (
            <Route {...route.routeProps} key={route.routeProps.path} />
          ))
        }
      </Routes>
    </BrowserRouter>
  )
}
