import { SalesGroupModel, SourceModel } from '@wolf/entities'
import { useSearchParams } from 'react-router-dom'
import { trpc } from './utils/trpc'
import { URL_PARAMS } from './utils/url-params'

type SalesGroupFilterParams = {
  salesGroupId?: SalesGroupModel['salesGroupId']
  fromDate?: SalesGroupModel['fromDate']
  toDate?: SalesGroupModel['toDate']
}

type SourceParams = {
  serialId?: SourceModel['serialId']
  name?: SourceModel['name']
  language?: SourceModel['language']
}

type GlobalFilterResponse = {
  salesGroupFilterParams: SalesGroupFilterParams
  sourceFilterParams: SourceParams
  changeSalesGroupParams: (params: { salesGroupId: SalesGroupFilterParams['salesGroupId']; replace?: boolean }) => void
  changeSourceParams: (params: { serialId: SourceParams['serialId']; replace?: boolean }) => void
}

export const useUrlGlobalFilterParams = (): GlobalFilterResponse => {
  const [searchParams, setSearchParams] = useSearchParams()

  const mergeParams = (newParams: URLSearchParams) => {
    const updatedParams = new URLSearchParams(searchParams)
    newParams.forEach((value, key) => {
      if (value) {
        updatedParams.set(key, value)
      } else {
        updatedParams.delete(key)
      }
    })
    return updatedParams
  }

  const changeSalesGroupParams = ({
    salesGroupId,
    replace,
  }: { salesGroupId: SalesGroupFilterParams['salesGroupId']; replace?: boolean }) => {
    const newParams = new URLSearchParams()
    if (salesGroupId) {
      newParams.set(URL_PARAMS.SALES_GROUP_ID, salesGroupId.toString())
    }
    const mergedParams = mergeParams(newParams)
    setSearchParams(mergedParams, { replace: replace || false })
  }

  const changeSourceParams = ({
    serialId,
    replace
  }: { serialId: SourceParams['serialId']; replace?: boolean }) => {
    const newParams = new URLSearchParams()
    if (serialId) {
      newParams.set(URL_PARAMS.SOURCE_ID, serialId.toString())
    }
    const mergedParams = mergeParams(newParams)
    setSearchParams(mergedParams, { replace: replace || false })
  }

  const salesGroupId = searchParams.get(URL_PARAMS.SALES_GROUP_ID)
    ? Number(searchParams.get(URL_PARAMS.SALES_GROUP_ID))
    : undefined

  const sourceId = searchParams.get(URL_PARAMS.SOURCE_ID)
    ? Number(searchParams.get(URL_PARAMS.SOURCE_ID))
    : undefined

  const { data: salesGroup } = trpc.salesGroups.getSalesGroup.useQuery(
    {
      salesGroupId: Number(salesGroupId),
      sourceId
    },
    {
      enabled: !!salesGroupId,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 6 // 5 minutes
    }
  )
  const { data: source } = trpc.sources.getSource.useQuery(Number(sourceId),
    {
      enabled: !!sourceId,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 6 // 5 minutes
    }
  )

  const fromDate = salesGroup ? salesGroup.fromDate : undefined
  const toDate = salesGroup ? salesGroup.toDate : undefined

  return {
    salesGroupFilterParams: {
      salesGroupId,
      fromDate,
      toDate,
    },
    sourceFilterParams: {
      serialId: sourceId,
      name: source?.name,
      language: source?.language
    },
    changeSalesGroupParams,
    changeSourceParams
  }
}
