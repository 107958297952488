import { Checkbox, InputNumber, Select } from 'antd'
import { useUrlIdsParams } from '../../hooks/useUrlIdsParams'
import { useUrlPickingParams } from '../../hooks/useUrlPickingParms'
import { PICKING_STATES_TRANSLATION } from '../../../../picking-states'
import { useUrlOtherParams } from '../../hooks/useUrlOtherParams'

const deliveryTypeValues = {
  'SINGLE': false,
  'MANAGED': true
}

export const BaseFilters: React.FC = () => {

  const { pickingParams, changePickingParams } = useUrlPickingParams()
  const { idsParams, changeIdsParams } = useUrlIdsParams()

  const { changeOtherParams, otherParams } = useUrlOtherParams()

  return (
    <div className='flex flex-wrap mb-4'>
      <div className='flex ml-1 mr-3'>
        <p className='mr-2'>ID Caja:</p>
        <InputNumber
          className='flex self-center w-40 h-8'
          placeholder='1234'
          min={0}
          value={idsParams.boxId}
          onChange={(value) => {
            changeIdsParams({
              boxId: value ? value : undefined,
              deliveryId: idsParams.deliveryId,
              orderId: idsParams.orderId
            })
          }}
        />
      </div>
      <div className='flex ml-1 mr-3'>
        <p className='mr-2'>ID Envío:</p>
        <InputNumber
          className='flex self-center w-40 h-8'
          placeholder='1234'
          min={0}
          value={idsParams.deliveryId}
          onChange={(value) => {
            changeIdsParams({
              boxId: idsParams.boxId,
              deliveryId: value ? value : undefined,
              orderId: idsParams.orderId
            })
          }}
        />
      </div>
      <div className='flex ml-1 mr-3'>
        <p className='mr-2'>ID Pedido:</p>
        <InputNumber
          className='flex self-center w-40 h-8'
          placeholder='1234'
          min={0}
          value={idsParams.orderId}
          onChange={(value) => {
            changeIdsParams({
              boxId: idsParams.boxId,
              deliveryId: idsParams.deliveryId,
              orderId: value ? value : undefined
            })
          }
          }
        />
      </div>
      <div className='flex ml-1 mr-3'>
        <p className='mr-2'>Estados:</p>
        <Select
          mode='multiple'
          value={pickingParams.pickingStates}
          onChange={((states) => {
            if (states.length === 0) {
              return changePickingParams({})
            }
            changePickingParams({ pickingStates: states })
          })}
          className='block self-center w-96 h-8 mr-2'
          allowClear
          options={PICKING_STATES_TRANSLATION}
        />
      </div>

      <div className='flex items-center mr-2'>
        <p className='my-0 mr-2'>Tipo de envío:</p>
        <Select
          value={otherParams.isManagedDelivery}
          allowClear={true}
          onChange={(value: boolean | undefined) => {
            changeOtherParams({ isManagedDelivery: value, shouldChangeLabel: otherParams.shouldChangeLabel })
          }}
          className='flex self-center w-24 h-8'
          options={[
            { label: 'Único', value: deliveryTypeValues.SINGLE },
            { label: 'Gestionado', value: deliveryTypeValues.MANAGED }
          ]}
        />
      </div>

      <div className='flex items-center ml-1 mr-3'>
        <Checkbox checked={otherParams.shouldChangeLabel} onChange={(change) => {
          changeOtherParams({ shouldChangeLabel: change.target.checked, isManagedDelivery: otherParams.isManagedDelivery })
        }}>Necesita reimprimir etiqueta</Checkbox>
      </div>
    </div>
  )
}
