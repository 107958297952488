import { Alert, Button, Table, Tooltip } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { checkPicked } from '../picking.utils'
import { RouterOutput, trpc } from '../../../utils/trpc'
import React from 'react'
import {
  productFromCode
} from '@wolf/barcodes'
import { usePageOutletContext } from '../../../page-outlet-context'
import { STATE_COLOURS } from '../../../state-colours'
import {
  CheckCircleOutlined,
  DeleteOutlined,
  IdcardOutlined,
  WarningTwoTone
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

type Props = {
  box: RouterOutput['boxes']['getBoxByIdWithAssemblies']
  onDeletePicking?: (pick: string) => void
}
type AssemblyProduct = RouterOutput['boxes']['getBoxByIdWithAssemblies']['assemblies'][0][0]

export const AssemblyTable = ({ box, onDeletePicking }: Props) => {
  const utils = trpc.useContext()
  const navigate = useNavigate()
  const { notificationInstance, salesGroupFilter, sourceFilter, workspace } = usePageOutletContext()

  const showCustomerLabelsColumn = box.picking.some(picking => !!picking.customerLabel)

  const deletePickingMutation = trpc.boxes.deletePicking.useMutation({
    onSuccess: (updatedBox) => {
      if (!updatedBox) {
        notificationInstance.success({
          message: 'Caja Desmontada',
          description: 'La caja se ha desmontado con éxito',
          duration: 2
        })
        utils.boxes.getBoxByIdWithAssemblies.refetch()
      } else {
        utils.boxes.getBoxByIdWithAssemblies.invalidate({
          id: Number(updatedBox.serialId),
          sourceId: sourceFilter.serialId
        })
      }
    },
    onError: (_, input) => {
      showError(`No se ha podido eliminar el picking del producto ${input.productId}`)
    },
  })

  const deletePicking = (pick: string) => {
    try {
      onDeletePicking && onDeletePicking(pick)
      const params = productFromCode(pick)
      const pickingParams = {
        ...params,
        sourceId: sourceFilter.serialId,
        workspaceId: workspace.serialId,
        lotId: params.date,
        offset: params.index,
        salesGroupId: salesGroupFilter.salesGroupId,
        boxId: box.serialId,
        deliveryGroupId: box.deliveryGroupId
      }
      deletePickingMutation.mutate(pickingParams)
    } catch (error) {
      showError(`Referencia incorrecta. El formato ${pick} no es correcto`)
      return
    }
  }

  const columns: ColumnsType<AssemblyProduct> = [
    {
      dataIndex: 'position',
      title: 'Posición',
      key: 'position',
      className: 'position-column w-10',
      render: (position: string) => {
        return (
          <p className='text-2xl font-bold m-0'>{position}</p>
        )
      },
    },
    {
      dataIndex: 'quantity',
      title: 'Cantidad',
      key: 'quantity',
      className: 'w-10',
      render: (quantity: string) => {
        return (
          <p className='text-2xl font-bold m-0'>{quantity}</p>
        )
      },
    },
    {
      dataIndex: 'productId',
      title: 'Producto',
      key: 'productId',
      className: 'w-10',
      render: (productId: string) => {
        return (
          <p className='text-2xl m-0'>{productId}</p>
        )
      },
    },
    {
      dataIndex: 'productName',
      title: 'Nombre',
      key: 'productName',
      className: 'w-96',
      render: (productName: string) => {
        return (
          <p className='text-lg m-0 mt-1'>
            {productName}
          </p>
        )
      },
    },
    ...(showCustomerLabelsColumn ? [{
      width: 10,
      title: <Tooltip title='Los productos requieren de una etiqueta de cliente por cada táper' placement='left'>
        <IdcardOutlined
          className='align-bottom text-3xl'
        />
      </Tooltip>,
      className: '!text-center !align-middle',
      key: 'customersPicking', dataIndex: 'customersPicking',
      render: (customersPicking: AssemblyProduct['customersPicking']) => {
        return (
          <div className='w-full'>
            {customersPicking.map((customerPicking, index) => {

              const picking = box.picking.find(picking => picking.customersPicking?.some(({ code }) => code === customerPicking.code))
              const tooltipTitle = picking ? `Es necesario añadir una etiqueta de cliente del producto para ${picking.customerLabel ?? ''}` : ''

              return (
                <React.Fragment key={index}>
                  {customerPicking.picked
                    ? <CheckCircleOutlined className='block text-2xl h-7 mt-1' />
                    : <Tooltip title={tooltipTitle} placement='left' >
                      <WarningTwoTone className='block text-3xl h-7 mb-1' twoToneColor={'red'} />
                    </Tooltip>
                  }
                </React.Fragment>
              )
            })}
          </div>
        )
      }
    }] : []),
    {
      dataIndex: 'picking',
      title: 'Picking',
      key: 'picking',
      className: 'w-64 max-w-16 !align-middle',
      render: (picking: Array<string>) => {
        return (
          <div>
            {picking.map((pick, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="flex w-full mb-1 last:mb-0">
                    <Tooltip title={pick} placement='left'>
                      <p className="mr-4 my-0 self-center overflow-hidden whitespace-nowrap text-ellipsis">{pick}</p>
                    </Tooltip>
                    <button
                      className={`${STATE_COLOURS.ERROR} rounded h-7 border border-solid border-black cursor-pointer`}
                      onClick={() => deletePicking(pick)}
                    >
                      <DeleteOutlined style={{ color: 'black' }} />
                    </button>
                  </div>
                </React.Fragment>
              )
            })}
          </div>
        )
      },
    },
  ]

  const showError = (description: string) =>
    notificationInstance.error({
      message: 'Error',
      description,
      className: 'w-full bg-red-200',
      duration: 0
    })

  return (
    <div>
      {box.assemblies.length === 1 && <Alert
        message="Este SalesGroup aún NO tiene estanterías definidas"
        description={
          <p>
            Todos los productos aparecerán en la tabla de "Productos sin estantería" hasta que éstas se definan. <br />
            Puedes hacerlo accediendo a
            <Button
              className='pl-1'
              type="link"
              onClick={() => navigate(`/configurations?salesGroupId=${salesGroupFilter.salesGroupId}&sourceId=${sourceFilter.serialId}`)}
            >
              Configuraciones
            </Button>
          </p>
        }
        type="info"
        showIcon
      />}
      {showCustomerLabelsColumn && <Alert
        className='mb-2'
        message="Este es un pedido con etiquetas de cliente"
        description={'Para cada uno de los táppers se tendrá que pegar y escanear una etiqueta con el nombre del cliente que se imprimirá automáticamente al hacer el picking del plato'}
        type="warning"
        showIcon
      />}
      {box.assemblies.map((assembly, index) =>
        assembly.length === 0 ? (
          <React.Fragment key={index}></React.Fragment>
        ) : (
          <React.Fragment key={index}>
            {index === box.assemblies.length - 1 && (
              <h2>Productos sin estantería</h2>
            )}
            <Table
              className="border-solid border border-slate-200 rounded-lg mb-2 overflow-hidden"
              columns={columns}
              dataSource={assembly}
              pagination={false}
              rowKey={(assembly) => assembly.productId}
              rowClassName={(record) => {
                return checkPicked(record, box)
              }}
            />
          </React.Fragment>
        )
      )}
    </div>
  )
}
