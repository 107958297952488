import { BoxModel } from '@wolf/entities'
import { useSearchParams } from 'react-router-dom'

type PickingParams = {
  pickingStates?: Array<BoxModel['pickingState']>
}

type PickingParamsResponse = {
  pickingParams: PickingParams
  changePickingParams: (params: PickingParams) => void
}

export const URL_PICKING_PARAMS = {
  PICKING_STATES: 'pickingStates'
} as const

export const useUrlPickingParams = (): PickingParamsResponse => {
  const [searchParams, setSearchParams] = useSearchParams()

  const changePickingParams = ({ pickingStates }: PickingParams) => {

    if (pickingStates && pickingStates.length > 0) {
      searchParams.set(URL_PICKING_PARAMS.PICKING_STATES, pickingStates.toString())
    } else {
      searchParams.delete(URL_PICKING_PARAMS.PICKING_STATES)
    }

    return setSearchParams(searchParams)
  }
  const pickingStates = searchParams.get(URL_PICKING_PARAMS.PICKING_STATES)?.split(',')

  if (!pickingStates) {
    return {
      pickingParams: {
        pickingStates
      },
      changePickingParams
    }
  }

  const validStates = pickingStates.reduce<Array<BoxModel['pickingState']>>((statesMap, state) => {
    if (state === 'EMPTY' || state === 'PARTIAL' || state === 'COMPLETED' || state === 'UNMOUNT') {
      statesMap.push(state)
    }
    return statesMap
  }, [])

  return {
    pickingParams: {
      pickingStates: validStates
    },
    changePickingParams
  }

}
