import React from 'react'
import { BoxSearchInput } from './components/BoxSearchInput'

export const BoxSearcher: React.FC = () => {

  return (
    <>
      <BoxSearchInput />
    </>
  )
}