import { Tabs } from 'antd'
import { Helmet } from 'react-helmet-async'
import { Title } from '../../components/Title'
import { AssemblyStations } from './AssemblyStations'
import { PrinterConfiguration } from '../printer-configuration/PrinterConfiguration'

export const Configurations: React.FC = () => (
  <>
    <Helmet
      title='Configuraciones - Wolf'
    />
    <Title>Configuraciones</Title>
    <Tabs
      className='mt-4'
      tabPosition={'left'}
      items={[
        {
          label: `Estanterías`,
          key: 'assembly-stations',
          children: <AssemblyStations />,
        },
        {
          label: `Impresora`,
          key: 'printer',
          children: <PrinterConfiguration />,
        }
      ]}
    />
  </>
)
