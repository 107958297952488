import React, { useRef, useState } from 'react'
import { Button, InputRef } from 'antd'
import { DownloadOutlined, LoadingOutlined, WarningTwoTone } from '@ant-design/icons'
import { RouterOutput, trpc } from '../../utils/trpc'
import { useNavigate, useParams } from 'react-router-dom'
import { AssemblyTable } from './components/AssemblyTable'
import { BoxPickingInput } from './components/BoxPickingInput'
import { BoxSearchInput } from './components/BoxSearchInput'
import { BoxLabelInput, BoxLabelTemplate } from '../boxes/components/BoxLabelTemplate'
import { formatDeliveryDay, formatDeliveryOption } from '../boxes/Boxes'
import { usePageOutletContext } from '../../page-outlet-context'
import { STATE_COLOURS } from '../../state-colours'
import { dayNames } from '../../utils/day-names'

type BoxWithAssemblies = RouterOutput['boxes']['getBoxByIdWithAssemblies']

export const BoxPicking: React.FC = () => {
  const refSearch = useRef<InputRef>(null)
  const navigate = useNavigate()
  const { salesGroupFilter, sourceFilter, notificationInstance: notificationApi } = usePageOutletContext()
  const { id } = useParams<{ id: string }>()

  const [boxLabelInput, setBoxLabelInput] = useState<Array<BoxLabelInput>>([])
  const [getBoxToPrint, setGetBoxToPrint] = useState<boolean>(false)

  const updateBoxesShouldChangeLabel = trpc.boxes.updateBoxShouldChangeLabel.useMutation()

  const queryResult = trpc.boxes.getBoxByIdWithAssemblies.useQuery(
    {
      id: Number(id),
      sourceId: sourceFilter.serialId
    }, {
      retry: 0,
      onError: (error) => {
        showError(`${error}`)
      },
      onSuccess: () => {
        setBoxLabelInput([])
      }
    })

  const showError = (errorMessage: string) => notificationApi.error({
    message: 'No se ha podido encontrar la Caja',
    description: errorMessage,
  })

  const formatBoxOrderIds = (orders: BoxWithAssemblies['orders']): Array<string> =>
    orders.map((order, index) => index === orders.length - 1 ? `${order}` : `${order}, `)

  const focusBoxPickingInput = () => {
    if (refSearch.current) {
      refSearch.current.focus()
    }
  }

  if (queryResult.isLoading) {
    return (<>
      <LoadingOutlined />
    </>)
  }

  return (
    <>
      {
        queryResult.isError
          ? (
            <BoxSearchInput />
          )
          : (
            <>
              <div className='flex'>
                <div className='w-3/4'>
                  <AssemblyTable box={queryResult.data} onDeletePicking={focusBoxPickingInput} />
                </div>
                <div className='w-1/4 h-fit ml-6 text-center pb-4'>
                  <BoxPickingInput box={queryResult.data} ref={refSearch} onCloseNotification={focusBoxPickingInput} />
                  <h4>ID Caja: {queryResult.data.serialId}</h4>
                  <h4>{queryResult.data.boxTypeName}</h4>
                  <div className='border-solid border border-slate-200 rounded-lg py-2'>
                    <h4>ID Envío: {queryResult.data.deliveryGroupId}</h4>
                    <p>{queryResult.data.address.firstname} {queryResult.data.address.lastname}</p>
                    <p>{queryResult.data.address.phone}</p>
                    <p>
                      {queryResult.data.address.street}, {queryResult.data.address.number}, {queryResult.data.address.floor}
                      <br />
                      ({queryResult.data.address.zipcode}) {queryResult.data.address.city}
                    </p>

                    {queryResult.data.currentRoute && <>
                      <p className='mt-2 mb-0'><span className='font-bold'>Ruta:</span>
                        {/* Link to route */}
                        <Button
                          type="link"
                          className='pl-1 py-0'
                          onClick={() => navigate(`/expeditions?salesGroupId=${salesGroupFilter.salesGroupId}&sourceId=${sourceFilter.serialId}&route=${queryResult.data.currentRoute?.route}&day=${queryResult.data.currentRoute?.day}`)}
                        >
                          {`${queryResult.data.currentRoute.route} - ${dayNames[queryResult.data.currentRoute.day]}`}
                        </Button>
                      </p>
                      <p className='mt-2'><span className='font-bold'>Parada:</span> {queryResult.data.currentRoute.stop}</p>
                    </>}
                    <p className='mt-2'><span className='font-bold'>Entrega:</span> {`${formatDeliveryDay(queryResult.data.deliveryDate)} ${formatDeliveryOption(queryResult.data.deliveryOption, queryResult.data.deliveryDate.start)}`}</p>
                    <p className='mt-2 px-1'><span className='font-bold'>ID Pedidos:</span> {formatBoxOrderIds(queryResult.data.orders)} </p>

                    {queryResult.data.shouldChangeLabel
                      ? <p>
                        <WarningTwoTone className='text-2xl mr-2' twoToneColor='#eb2f96' />
                        <strong className='bg-red-300'>El envío ha cambiado, comprobar ruta y etiqueta</strong>
                      </p>
                      : undefined
                    }

                  </div>
                  <Button className='mt-2' type="primary" icon={<DownloadOutlined />} onClick={() => {
                    updateBoxesShouldChangeLabel.mutate({
                      boxesId: [queryResult.data.serialId],
                      shouldChangeLabel: false
                    })
                    setBoxLabelInput([{
                      address: queryResult.data.address,
                      boxId: queryResult.data.serialId,
                      boxTypeName: queryResult.data.boxTypeName,
                      deliveryGroupId: queryResult.data.deliveryGroupId,
                      deliveryGroupIndex: queryResult.data.deliveryGroupIndex,
                      deliveryOption: formatDeliveryOption(queryResult.data.deliveryOption, queryResult.data.deliveryDate.start),
                      deliveryGroupTotalBoxes: queryResult.data.deliveryGroupTotalBoxes,
                      route: queryResult.data.currentRoute ?? { day: 0, route: 'SIN RUTA' }
                    }])
                    setGetBoxToPrint(true)
                  }}>Descargar etiqueta</Button>
                </div>
              </div>
              <div className='border-solid border border-slate-200 rounded-lg flex justify-center'>
                <p className='pr-8'>Estados:</p>
                <p className='pr-8'> <span className={`${STATE_COLOURS.EMPTY} py-0.5 px-4 mr-1 border border-solid rounded`} /> Producto Incompleto</p>
                <p className='pr-8'><span className={`${STATE_COLOURS.PARTIAL} py-0.5 px-4 mr-1 border border-solid rounded`} /> Producto Completo</p>
                <p className='pr-8'><span className={`${STATE_COLOURS.COMPLETED} py-0.5 px-4 mr-1 border border-solid rounded`} /> Caja Completa</p>
                <p className='pr-8'><span className={`${STATE_COLOURS.ERROR} py-0.5 px-4 mr-1 border border-solid rounded`} /> Exceso de Producto</p>
              </div>

              {getBoxToPrint && boxLabelInput.length > 0 && (
                <BoxLabelTemplate
                  boxLabelInput={boxLabelInput}
                  onClose={() => {
                    setGetBoxToPrint(false)
                  }}
                />
              )}
            </>
          )
      }
    </>
  )
}
