import React, { useEffect, useRef, useState } from 'react'
import Search from 'antd/es/input/Search'
import { Button, Descriptions, InputRef } from 'antd'
import { RouterOutput, trpc } from '../../../utils/trpc'
import { usePageOutletContext } from '../../../page-outlet-context'
import { boxFromCode } from '@wolf/barcodes'
import { ExpeditionTable } from './ExpeditionTable'
import { STATE_COLOURS } from '../../../state-colours'
import { SalesGroupRoute } from '@wolf/entities'
import { ExpeditionSelect } from './ExpeditionSelect'
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'
import { useUrlRouteParams } from '../hooks/useUrlRouteParams'
import { Helmet } from 'react-helmet-async'
import { dayNames } from '../../../utils/day-names'
import { PackingListTemplate } from '../../../components/packing-list/PackingListTemplate'

export const EXPEDITION_TYPES = {
  NO_CHEKED: 'NO_CHEKED',
  ON_ANOTHER_ROUTE: 'ON_ANOTHER_ROUTE',
  FROM_ANOTHER_ROUTE: 'FROM_ANOTHER_ROUTE',
  CHECKED: 'CHECKED'
} as const

export type ExpeditionTypes = keyof typeof EXPEDITION_TYPES

type ExpeditionProps = {
  activeRoute: Pick<SalesGroupRoute, 'day' | 'route'>
  routes: Array<Pick<SalesGroupRoute, 'day' | 'route'>>
}

type ExpeditionBox = RouterOutput['boxes']['getRouteBoxesSummary'][number]['data']['unchecked']

const SearchInput: React.FC<{ onSearch: (value: string) => void; inputRef: React.RefObject<InputRef> }> = ({ onSearch, inputRef }) => {
  const [codeValue, setCodeValue] = useState<string>('')

  return (
    <Search
      className="mr-4 w-64"
      placeholder={'ID'}
      allowClear
      onSearch={() => {
        onSearch(codeValue)
        setCodeValue('')
      }}
      onChange={(event) => {
        setCodeValue(event.target.value)
      }}
      value={codeValue}
      autoFocus
      enterButton="Check"
      ref={inputRef}
    />
  )
}

export const Expedition: React.FC<ExpeditionProps> = ({ activeRoute, routes }) => {
  const [printPackingList, setPrintPackingList] = useState<boolean>(false)
  const [allBoxesToPrint, setAllBoxesToPrint] = useState<ExpeditionBox>([])
  const { salesGroupFilter, sourceFilter, notificationInstance } = usePageOutletContext()
  const { changeParams } = useUrlRouteParams()

  const utils = trpc.useContext()
  const inputRef = useRef<InputRef>(null)

  const queryInput = {
    sourceId: sourceFilter.serialId,
    salesGroupId: salesGroupFilter.salesGroupId,
    routes: [{
      route: activeRoute.route,
      day: activeRoute.day
    }],
    isSingleRoute: true
  }

  const boxesQuery = trpc.boxes.getRouteBoxesSummary.useQuery(queryInput, {
    enabled: salesGroupFilter.salesGroupId > 0,
    onSuccess: (data) => {
      setAllBoxesToPrint(data[0].data.unchecked
        .concat(data[0].data.checked)
        .concat(data[0].data.checkedOnAnotherRoute))
      setPrintPackingList(false)
    }
  })

  const boxSummaryData = boxesQuery.data?.[0]

  const addBoxExpeditionMutation = trpc.boxes.updateBoxExpeditions.useMutation({
    onError: (error) => {
      showError(error.message)
    },
    onSuccess: (updatedBox) => {

      const currentBoxRoute = updatedBox.expeditionEntries[updatedBox.expeditionEntries.length - 1]

      showSuccess(currentBoxRoute.route)

      const checkedBoxes = [...boxSummaryData?.data.checked ?? []]
      const uncheckedBoxes = [...boxSummaryData?.data.unchecked ?? []]
      const checkedFromAnotherRoute = [...boxSummaryData?.data.checkedFromAnotherRoute ?? []]
      const checkedOnAnotherRoute = [...boxSummaryData?.data.checkedOnAnotherRoute ?? []]

      // Case 1: Unchecked -> checked
      if (currentBoxRoute.route === activeRoute.route && currentBoxRoute.day === activeRoute.day) {

        const updatedUncheckedBoxes = uncheckedBoxes.filter(box => box.serialId !== updatedBox.serialId)
        const updatedCheckedFromAnotherRoute = checkedFromAnotherRoute.filter(box => box.serialId !== updatedBox.serialId)
        const updatedCheckedOnAnotherRoute = checkedOnAnotherRoute.filter(box => box.serialId !== updatedBox.serialId)

        if (updatedBox.deliveryGroup.currentRoute?.route === activeRoute.route && updatedBox.deliveryGroup.currentRoute?.day === activeRoute.day) {
          checkedBoxes.push(updatedBox)
        } else {
          updatedCheckedFromAnotherRoute.push(updatedBox)
        }

        return utils.boxes.getRouteBoxesSummary.setData(queryInput, ([originalRoute] = []) => [{
          ...originalRoute,
          data: {
            checked: checkedBoxes,
            checkedFromAnotherRoute: updatedCheckedFromAnotherRoute,
            checkedOnAnotherRoute: updatedCheckedOnAnotherRoute,
            unchecked: updatedUncheckedBoxes
          },
          summary: {
            ...originalRoute.summary,
            checked: checkedBoxes.length
          }
        }])
      }

      // Case 2: Checked -> unchecked
      if (currentBoxRoute.route === 'NO_ROUTE' && currentBoxRoute.day === -1) {

        const updatedCheckedBoxes = checkedBoxes.filter(box => box.serialId !== updatedBox.serialId)
        const updatedCheckedFromAnotherRoute = checkedFromAnotherRoute.filter(box => box.serialId !== updatedBox.serialId)
        const updatedCheckedOnAnotherRoute = checkedOnAnotherRoute.filter(box => box.serialId !== updatedBox.serialId)

        if (checkedBoxes.length !== updatedCheckedBoxes.length) {
          uncheckedBoxes.push(updatedBox)
        }

        return utils.boxes.getRouteBoxesSummary.setData(queryInput, ([originalRoute] = []) => [{
          ...originalRoute,
          data: {
            checked: updatedCheckedBoxes,
            checkedFromAnotherRoute: updatedCheckedFromAnotherRoute,
            checkedOnAnotherRoute: updatedCheckedOnAnotherRoute,
            unchecked: uncheckedBoxes
          },
          summary: {
            ...originalRoute.summary,
            checked: updatedCheckedBoxes.length
          }
        }])
      }
    },
  })

  const showError = (errorMessage: string) =>
    notificationInstance.error({
      message: 'Código incorrecto',
      description: errorMessage
    })

  const showSuccess = (route: string) =>
    notificationInstance.success({
      message: 'Código correcto',
      description: route === 'NO_ROUTE' ? 'Caja eliminada de la ruta' : 'Caja checkeada',
      duration: 2,
    })

  const onSearch = (codeValue: string) => {
    try {
      if (!activeRoute) {
        return showError('Selecciona una ruta')
      }

      if (codeValue) {
        const box = boxFromCode(codeValue)

        const expeditionEntry = {
          boxId: box.boxId,
          route: activeRoute.route,
          day: activeRoute.day
        }

        addBoxExpeditionMutationFn(expeditionEntry)
      }
    } catch (error) {
      showError(`${error}`)
    }
  }

  const addBoxExpeditionMutationFn = (expedition: { boxId: number; route: string; day: number }) => {
    addBoxExpeditionMutation.mutate(expedition)
  }

  const onDeleteCheck = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const classNameByRouteState = () => {
    if (!boxSummaryData) {
      return
    }
    if (boxSummaryData.data.checkedFromAnotherRoute.length > 0) {
      return STATE_COLOURS.ERROR
    }
    if (boxSummaryData.data.checked.length > 0 && boxSummaryData.data.checked.length !== boxSummaryData.summary.ordered) {
      return STATE_COLOURS.PARTIAL
    }
    if (boxSummaryData.data.checked.length > 0 && boxSummaryData.data.checked.length === boxSummaryData.summary.ordered) {
      return STATE_COLOURS.COMPLETED
    }
    return STATE_COLOURS.EMPTY
  }

  const print = () => {
    // Print in the next react loop
    setTimeout(() => (setPrintPackingList(true)))
  }

  useEffect(() => {
    if (activeRoute.route && activeRoute.day) {
      if (!routes.some((route) => route.route === activeRoute.route && route.day === activeRoute.day)) {
        return changeParams({})
      }
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }
  }, [activeRoute])

  return (
    <>
      <Helmet
        title={`${activeRoute.route} - ${dayNames[activeRoute.day]} - Wolf`}
      />
      <div className='flex flex-wrap mt-4 self-center'>
        <SearchInput onSearch={onSearch} inputRef={inputRef} />
        <ExpeditionSelect routes={routes} />
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={print}
        >
          Descargar Albarán
        </Button>
        {printPackingList &&
          <PackingListTemplate
            route={activeRoute}
            boxes={allBoxesToPrint}
            onClose={() => {
              setPrintPackingList(false)
            }}
          />
        }
      </div>

      {boxesQuery.isLoading || !boxSummaryData
        ? <LoadingOutlined />
        : <>
          <Descriptions bordered layout={'vertical'} className='mt-6'>
            <Descriptions.Item label="CAJAS" className='w-1/3'>
              {boxSummaryData.summary.ordered}
            </Descriptions.Item>
            <Descriptions.Item label="MONTADAS" className='w-1/3'>
              {boxSummaryData.summary.mounted.length}
            </Descriptions.Item>
            <Descriptions.Item label="COMPROBADAS" className='w-1/3'>
              {boxSummaryData.summary.checked}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions bordered column={4} layout={'vertical'} className='mt-6 w-full'>
            <Descriptions.Item label="SIN COMPROBAR" className='align-baseline w-2/12 !px-2 !text-center'>
              <ExpeditionTable
                boxes={boxSummaryData.data.unchecked}
                type={EXPEDITION_TYPES.NO_CHEKED}
                classNameByRouteState={classNameByRouteState}
              />
            </Descriptions.Item>
            <Descriptions.Item label="EN OTRA RUTA" className='align-baseline w-3/12 !px-2 !text-center'>
              <ExpeditionTable
                boxes={boxSummaryData.data.checkedOnAnotherRoute}
                type={EXPEDITION_TYPES.ON_ANOTHER_ROUTE}
                classNameByRouteState={classNameByRouteState}
              />
            </Descriptions.Item>
            <Descriptions.Item label="DE OTRA RUTA" className='align-baseline w-4/12 !px-2 !text-center'>
              <ExpeditionTable
                boxes={boxSummaryData.data.checkedFromAnotherRoute}
                type={EXPEDITION_TYPES.FROM_ANOTHER_ROUTE}
                addBoxExpeditionMutationFn={addBoxExpeditionMutationFn}
                onDeleteCheck={onDeleteCheck}
                classNameByRouteState={classNameByRouteState}
              />
            </Descriptions.Item>
            <Descriptions.Item label="COMPROBADAS" className={`align-baseline w-3/12 first:!px-0 !px-2 !text-center`}>
              <ExpeditionTable
                boxes={boxSummaryData.data.checked}
                type={EXPEDITION_TYPES.CHECKED}
                addBoxExpeditionMutationFn={addBoxExpeditionMutationFn}
                onDeleteCheck={onDeleteCheck}
                classNameByRouteState={classNameByRouteState}
              />
            </Descriptions.Item>
          </Descriptions>

          <div className='border-solid border border-slate-200 rounded-lg flex mt-6 pl-4'>
            <p className='pr-8 w-32'>Estados ruta:</p>
            <p className='pr-8'> <span className={`${STATE_COLOURS.EMPTY} py-0.5 px-4 mr-1 border border-solid rounded`} /> Vacía</p>
            <p className='pr-8'><span className={`${STATE_COLOURS.PARTIAL} py-0.5 px-4 mr-1 border border-solid rounded`} /> Parcialmente checkeada</p>
            <p className='pr-8'><span className={`${STATE_COLOURS.COMPLETED} py-0.5 px-4 mr-1 border border-solid rounded`} /> Completamente checkeada</p>
            <p><span className={`${STATE_COLOURS.ERROR} py-0.5 px-4 mr-1 border border-solid rounded`} /> Contiene cajas de otra ruta</p>
          </div>
          <div className='border-solid border border-slate-200 rounded-lg flex mt-6 pl-4'>
            <p className='pr-8 w-32'>Estados cajas:</p>
            <p className='pr-8'> <span className={`${STATE_COLOURS.EMPTY} py-0.5 px-4 mr-1 border border-solid rounded`} /> Montada</p>
            <p><span className={`${STATE_COLOURS.ERROR} py-0.5 px-4 mr-1 border border-solid rounded`} /> No montada</p>
          </div>
        </>}
    </>
  )

}
