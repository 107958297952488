import Search from 'antd/es/input/Search'
import { useNavigate } from 'react-router-dom'
import { boxFromCode } from '@wolf/barcodes'
import { usePageOutletContext } from '../../../page-outlet-context'
import { useState } from 'react'

export const BoxSearchInput = () => {
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined)

  const navigate = useNavigate()

  const { notificationInstance } = usePageOutletContext()

  const showError = (errorMessage: string) =>
    notificationInstance.error({
      description: errorMessage,
      message: 'Caja no encontrada',
    })

  const onSearch = () => {
    if (!searchValue) {
      return
    }

    try {
      const params = boxFromCode(searchValue)
      return navigate(`/picking/${params.boxId}`)
    } catch (error) {
      showError(`${error}`)
    }

    setSearchValue(undefined)
  }

  return (
    <div className="flex items-center w-2/4">
      <p>Referencia:</p>
      <Search
        className="flex-1 ml-4"
        placeholder={'Caja'}
        allowClear
        onSearch={onSearch}
        onChange={(event) => {
          setSearchValue(event.target.value)
        }}
        value={searchValue}
        autoFocus
        enterButton="+"
      />
    </div>
  )
}
