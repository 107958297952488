import {
  DropboxOutlined,
  LoadingOutlined,
  LogoutOutlined,
  OrderedListOutlined,
  PrinterOutlined,
  SettingOutlined,
  TableOutlined,
  ProjectOutlined,
  BarcodeOutlined,
  DragOutlined,
  SyncOutlined,
  EnvironmentOutlined,
  ApartmentOutlined,
  CalendarOutlined,
  BlockOutlined,
  FileExcelOutlined,
} from '@ant-design/icons'
import { Layout, Menu, notification } from 'antd'
import { Content } from 'antd/es/layout/layout'
import Sider from 'antd/es/layout/Sider'
import React, { useEffect, useState } from 'react'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import { useLogout, useUserLoggedTrack } from '../pages/Login'
import { useDefaultConfiguration } from '../useDefaultConfiguration'
import { PageHeader } from './PageHeader'
import type { Models } from '@wolf/api'
import { useUrlGlobalFilterParams } from '../useUrlGlobalFilterParams'
import { RoutesMap } from '../routes-map'

export const Page: React.FC = () => {
  const [notificationInstance, notificationHolder] = notification.useNotification()
  const logout = useLogout()
  const current = useLocation()
  useUserLoggedTrack()

  const defaultConfig = useDefaultConfiguration()
  const { salesGroupFilterParams, changeSalesGroupParams, sourceFilterParams, changeSourceParams } = useUrlGlobalFilterParams()

  const [salesGroupFilter, setSalesPlanFilter] = useState<Models.SalesGroupFilterType>(
    {
      salesGroupId: -1,
      fromDate: new Date(),
      toDate: new Date(),
    }
  )
  const [sourceFilter, setSourceFilter] = useState<Models.SourceFilterType>(
    {
      serialId: -1,
      name: '',
      language: 'ES'
    }
  )
  useEffect(() => {
    if (defaultConfig.isLoading === false) {
      setSalesPlanFilter({
        salesGroupId:
          salesGroupFilterParams.salesGroupId ||
          defaultConfig.defaultSalesGroup.salesGroupId,
        fromDate:
          salesGroupFilterParams.fromDate ||
          defaultConfig.defaultSalesGroup.fromDate,
        toDate:
          salesGroupFilterParams.toDate ||
          defaultConfig.defaultSalesGroup.toDate,
      })

      setSourceFilter({
        serialId:
          sourceFilterParams.serialId ||
          defaultConfig.defaultSource.serialId,
        name:
          sourceFilterParams.name ||
          defaultConfig.defaultSource.name,
        language:
          sourceFilterParams.language ||
          defaultConfig.defaultSource.language,
      })

      if (!salesGroupFilterParams.salesGroupId) {
        changeSalesGroupParams({
          salesGroupId: defaultConfig.defaultSalesGroup.salesGroupId
        })
      }

      if (!sourceFilterParams.serialId) {
        changeSourceParams({
          serialId: defaultConfig.defaultSource.serialId,
        })
      }
    }
  }, [defaultConfig.isLoading])

  return (
    <Layout className="min-h-screen">
      {notificationHolder}
      {!defaultConfig.isLoading && (
        <PageHeader
          workspace={defaultConfig.workspace.name}
          salesGroupFilter={salesGroupFilter}
          setSalesPlanFilter={setSalesPlanFilter}
          sourceFilter={sourceFilter}
          setSourceFilter={setSourceFilter}
        />
      )}
      <Layout>
        <Sider collapsible breakpoint='xxl' className='border-r border-solid border-gray-100 border-0 !bg-white'>
          <NavLink
            to={RoutesMap.protected.home.pathBuilder()}
            className="flex flex-col items-center m-6"
          >
            <img src="/wolf_logo.svg" className="w-3/6" />
          </NavLink>
          <Menu
            mode="inline"
            selectedKeys={[current.pathname]}
            style={{ border: 0 }}
            defaultSelectedKeys={[current.pathname]}
            items={[
              {
                icon: <BarcodeOutlined />,
                label: (
                  <NavLink to={RoutesMap.protected.picking.pathBuilder()}>
                    Montajes
                  </NavLink>
                ),
                key: RoutesMap.protected.picking.pathBuilder(),
              },
              {
                icon: <BlockOutlined />,
                label: 'Envíos',
                key: 'Envíos',
                children: [
                  {
                    icon: <DropboxOutlined />,
                    label: (
                      <NavLink to={RoutesMap.protected.boxes.pathBuilder()}>
                        Cajas
                      </NavLink>
                    ),
                    key: RoutesMap.protected.boxes.pathBuilder(),
                  },
                  {
                    icon: <CalendarOutlined />,
                    label: (
                      <NavLink to={RoutesMap.protected.updateDeliverySalesGroup.pathBuilder()}>
                        Cambiar semana de envío
                      </NavLink>
                    ),
                    key: RoutesMap.protected.updateDeliverySalesGroup.pathBuilder(),
                  },
                ],
              },
              {
                icon: <TableOutlined />,
                label: 'Productos',
                key: 'Productos',
                children: [
                  {
                    icon: <OrderedListOutlined />,
                    label: (
                      <NavLink to={RoutesMap.protected.stocks.pathBuilder()}>
                        Estocaje
                      </NavLink>
                    ),
                    key: RoutesMap.protected.stocks.pathBuilder(),
                  },
                  {
                    icon: <PrinterOutlined />,
                    label: (
                      <NavLink to={RoutesMap.protected.labels.pathBuilder()}>
                        Etiquetas
                      </NavLink>
                    ),
                    key: RoutesMap.protected.labels.pathBuilder(),
                  },
                ],
              },
              {
                icon: <ProjectOutlined />,
                label: (
                  <NavLink to={RoutesMap.protected.stats.pathBuilder()}>
                    Estadísticas
                  </NavLink>
                ),
                key: RoutesMap.protected.stats.pathBuilder(),
              },
              {
                icon: <EnvironmentOutlined />,
                label: 'Rutas',
                key: 'Rutas',
                children: [
                  {
                    icon: <ApartmentOutlined />,
                    label: (
                      <NavLink to={RoutesMap.protected.routes.pathBuilder()}>
                        Resumen
                      </NavLink>
                    ),
                    key: RoutesMap.protected.routes.pathBuilder(),
                  },
                  {
                    icon: <SyncOutlined />,
                    label: (
                      <NavLink
                        to={RoutesMap.protected.syncRoutes.pathBuilder()}
                      >
                        Sincronizar rutas
                      </NavLink>
                    ),
                    key: RoutesMap.protected.syncRoutes.pathBuilder(),
                  },
                  {
                    icon: <DragOutlined />,
                    label: (
                      <NavLink
                        to={RoutesMap.protected.expeditions.pathBuilder()}
                      >
                        Expediciones
                      </NavLink>
                    ),
                    key: RoutesMap.protected.expeditions.pathBuilder(),
                  },
                  {
                    icon: <FileExcelOutlined />,
                    label: (
                      <NavLink
                        to={RoutesMap.protected.exportRoutes.pathBuilder()}
                      >
                        Exportar rutas
                      </NavLink>
                    ),
                    key: RoutesMap.protected.exportRoutes.pathBuilder(),
                  },
                ],
              },
              {
                icon: <SettingOutlined />,
                label: (
                  <NavLink
                    to={RoutesMap.protected.configurations.pathBuilder()}
                  >
                    Configuraciones
                  </NavLink>
                ),
                key: RoutesMap.protected.configurations.pathBuilder(),
              },
              {
                type: 'divider',
              },
              {
                icon: <LogoutOutlined />,
                label: 'Salir',
                key: 'logout',
                onClick: logout,
              },
            ]}
          ></Menu>
        </Sider>
        <Layout>
          <Content className="px-6 py-3 bg-white">
            {defaultConfig.isLoading ? (
              <LoadingOutlined />
            ) : (
              <Outlet
                context={{
                  ...defaultConfig,
                  salesGroupFilter,
                  notificationInstance,
                  sourceFilter
                }}
              />
            )}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}
