import React, { useEffect, useState } from 'react'
import type { ColumnsType } from 'antd/es/table'
import { InputNumber, Table } from 'antd'
import { trpc } from '../../utils/trpc'
import { Link } from 'react-router-dom'
import { StocksEntryForm } from './components/StocksEntryForm'
import { InputStockBarcode } from './components/InputStockBarcode'
import { Title } from '../../components/Title'
import { usePageOutletContext } from '../../page-outlet-context'
import { Helmet } from 'react-helmet-async'

interface DataType {
  productId: number
  name: string
  stock: number
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Product Id',
    dataIndex: 'productId',
    key: 'productId',
  },
  {
    title: 'Producto',
    dataIndex: 'name',
    key: 'name',
    width: '50%',
  },
  {
    title: 'Estocaje',
    dataIndex: 'stock',
    key: 'stock',
  },
  {
    title: 'Acción',
    dataIndex: 'productId',
    key: 'action',
    render: (productId: number) => (
      <Link to={`${window.location.pathname}/${productId}`}>Ir a detalles</Link>
    ),
  },
]

export const Stocks: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(50)

  const [searchProductId, setSearchProductId] = useState<number | null>(null)

  const { salesGroupFilter, sourceFilter } = usePageOutletContext()

  const queryResult = trpc.products.getProductsStocks.useQuery(
    {
      pagination: {
        pageSize,
        currentPage,
      },
      salesGroupFilter,
      productId: searchProductId ?? undefined,
      sourceId: sourceFilter.serialId
    },
    { enabled: salesGroupFilter.salesGroupId !== -1 }
  )

  const onPaginationChange = (current: number, pageSize: number) => {
    setCurrentPage(current)
    setPageSize(pageSize)
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [searchProductId])

  return (
    <>
      <Helmet
        title='Estocaje - Wolf'
      />
      <Title>Estocaje</Title>
      <InputStockBarcode onSuccess={() => queryResult.refetch()} />
      <div className="mb-4">
        <div className="flex mb-4">
          <p className="w-fit mr-2">Id de producto:</p>
          <InputNumber
            className="flex self-center w-40 h-8 pl-2"
            placeholder="1234"
            min={0}
            value={searchProductId}
            onChange={setSearchProductId}
          />
        </div>
        <Table
          columns={columns}
          dataSource={queryResult.data?.result}
          pagination={{
            className: 'pr-4',
            total: queryResult.data?.total,
            pageSize,
            current: currentPage,
            showSizeChanger: true,
            onChange: onPaginationChange,
          }}
          loading={queryResult.isLoading}
          rowKey={(record) => record.productId}
        />
      </div>
      <StocksEntryForm onSuccess={() => queryResult.refetch()} />
    </>
  )
}
