import { createPortal } from 'react-dom'
import React from 'react'

type PdfFrameProps = {
  children: React.ReactNode
  onClose: () => void
  waitingTime: 1000 | 10000  // Defines the time it takes for the children to be prepared for rendering. Necessary for PDFs with many pages.
}

export const PdfFrame: React.FC<PdfFrameProps> = ({ children, onClose, waitingTime }) => {
  const [contentRef, setContentRef] = React.useState<HTMLIFrameElement | null>(null)
  const container = contentRef?.contentWindow?.document?.body

  React.useEffect(() => {
    setTimeout(() => {
      contentRef?.contentWindow?.print()
    }, waitingTime)
  }, [contentRef])

  React.useEffect(() => {
    if (contentRef) {
      contentRef.contentWindow?.addEventListener('afterprint', () => {
        onClose()
      })
    }
  }, [contentRef])

  return (
    <iframe
      ref={setContentRef}
      style={{visibility: 'hidden', position: 'absolute', top:'9999'}}
    >
      {container && createPortal(children, container)}
    </iframe>
  )
}
