import { createTRPCReact, httpBatchLink, httpLink, HttpBatchLinkOptions } from '@trpc/react-query'
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server'
import type { AppRouter } from '@wolf/api'
import SuperJSON from 'superjson'
import { env } from '../env'
import { AccessTokenKey } from '../pages/Login'

export const trpc = createTRPCReact<AppRouter>()

export type RouterInput = inferRouterInputs<AppRouter>
export type RouterOutput = inferRouterOutputs<AppRouter>

const linkConfig: HttpBatchLinkOptions = {
  url: `${env.REACT_API_URL}/trpc`,
  headers() {
    const accessToken = localStorage.getItem(AccessTokenKey)

    if (!accessToken) {
      return {}
    }

    return {
      Authorization: accessToken
    }
  },
}

export const trpcClient = trpc.createClient({
  links: [
    env.isDev
      ? httpLink(linkConfig)
      : httpBatchLink(linkConfig),
  ],
  transformer: SuperJSON
})
