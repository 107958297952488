import { BoxModel } from '@wolf/entities'
import { STATE_COLOURS } from '../../state-colours'
import { RouterOutput } from '../../utils/trpc'

type AssemblyProduct =
  RouterOutput['boxes']['getBoxByIdWithAssemblies']['assemblies'][0][0]

const getPickedQuantity = (
  productId: number,
  box: RouterOutput['boxes']['getBoxByIdWithAssemblies']
) => {
  const productPickings = box.picking.filter(
    (pick) => pick.productId === productId
  )

  return productPickings.reduce<number>(
    (quantity, { picking }) => quantity + picking.length,
    0
  )
}

/**
 * Get the number of required picks for a product in the box
 */
const getRequiredPicks = (
  productId: number,
  box: RouterOutput['boxes']['getBoxByIdWithAssemblies']
) => {
  const product = box.assemblies
    .flatMap((assembly) => assembly)
    .find((product) => product.productId === productId)

  return product ? product.quantity : -1
}

export const isProductPicked = (
  value: number,
  box: RouterOutput['boxes']['getBoxByIdWithAssemblies']
) => getPickedQuantity(value, box) === getRequiredPicks(value, box)

export type PickingReferenceResponse = {
  sourceId: number
  workspaceId: number
  productId: number
  lotId: number
  offset: number
}

export type CustomerPickingReferenceResponse = {
  sourceId: number
  workspaceId: number
  deliveryGroupId: number
  boxId: number
  productId: number
  index: number
  customerLabel: string
}

export const checkPicked = (
  record: AssemblyProduct,
  box: RouterOutput['boxes']['getBoxByIdWithAssemblies']
) => {
  if (
    getPickedQuantity(record.productId, box) >
    getRequiredPicks(record.productId, box)
  ) {
    return STATE_COLOURS.ERROR
  }

  const allProducstPicked = box.picking.every((picking) => isProductPicked(picking.productId, box))
  const allLabelsPicked = box.picking.every(picking => picking.customersPicking ? picking.customersPicking.every(({ picked }) => picked) : true)

  if (allProducstPicked && allLabelsPicked) {
    return STATE_COLOURS.COMPLETED
  }

  return isProductPicked(record.productId, box) ? STATE_COLOURS.PARTIAL : STATE_COLOURS.EMPTY
}

export const getPickingState = (picking: BoxModel['picking']) => {

  // If there's something to unmount
  const unmount = picking.some(picking => picking.picking.length > picking.quantity)
  if (unmount) {
    return 'UNMOUNT'
  }

  // If there's nothing mounted
  const empty = picking.every((picking) => picking.picking.length === 0)
  if (empty) {
    return 'EMPTY'
  }

  // If it's all mounted
  const fullProducts = picking.every((picking) => picking.picking.length === picking.quantity)
  const fullLabels = picking.every(picking => picking.customersPicking ? picking.customersPicking.every(({ picked }) => picked) : true)
  if (fullProducts && fullLabels) {
    return 'COMPLETED'
  }

  // If there's something mounted
  return 'PARTIAL'
}
