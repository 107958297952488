import { Checkbox, Select } from 'antd'
import { usePageOutletContext } from '../../../../page-outlet-context'
import { dayNames } from '../../../../utils/day-names'
import { trpc } from '../../../../utils/trpc'
import { useUrlRouteParams } from '../../hooks/useUrlRouteParams'

export const RoutesFilter: React.FC<{ showWithoutRoute?: boolean }> = ({ showWithoutRoute = true }) => {

  const { salesGroupFilter, sourceFilter } = usePageOutletContext()
  const { routeParams, changeRouteParams } = useUrlRouteParams()

  const salesGroupsRoutes = trpc.salesGroups.getSalesGroupsRoutes.useQuery(
    {
      salesGroupId: salesGroupFilter.salesGroupId,
      sourceId: sourceFilter.serialId,
      dayFilter: routeParams.day },
    {
      enabled: salesGroupFilter.salesGroupId !== -1,
      staleTime: 1000 * 60 * 1
    }
  )

  const getRoutesValue = () => {
    if (!routeParams.routes) {
      return undefined
    } else {
      return routeParams.routes.map(({ route, day }) => JSON.stringify({ route, day }))
    }
  }

  return (
    <div className='flex mb-4 ml-1 mr-3'>
      {showWithoutRoute && <Checkbox
        className='ml-4 mr-8 self-center'
        disabled={routeParams.day !== undefined && routeParams.day !== null}
        checked={routeParams.day === null && routeParams.routes === null}
        onChange={(value) => {
          value.target.checked
            ? changeRouteParams({ day: null, routes: null })
            : changeRouteParams({})
        }}
      >
        Sin Ruta
      </Checkbox>}

      <p className='mr-2'>Día:</p>
      <Select
        value={routeParams.day}
        onChange={((day) => {
          changeRouteParams({ day, routes: undefined })
        })}
        className='block self-center w-32 h-8 mr-4'
        disabled={routeParams.day === null}
        allowClear
        options={[
          { label: 'Domingo', value: 0 },
          { label: 'Lunes', value: 1 },
          { label: 'Martes', value: 2 },
          { label: 'Miércoles', value: 3 },
          { label: 'Jueves', value: 4 },
          { label: 'Viernes', value: 5 },
          { label: 'Sábado', value: 6 },
        ]}
      />
      <p className='mr-2'>Rutas:</p>
      <Select
        value={getRoutesValue()}
        autoClearSearchValue={false}
        mode={'multiple'}
        maxTagCount={'responsive'}
        disabled={routeParams.day === null}
        onChange={((routes) => {
          if (routes.length > 0) {
            return changeRouteParams({ routes: routes.map((route) => JSON.parse(route)), day: routeParams.day })
          } else {
            return changeRouteParams({ routes: undefined, day: routeParams.day })
          }
        })}
        className='block self-center w-80 h-8 mr-2'
        showSearch
        allowClear
        options={
          salesGroupsRoutes.data && salesGroupsRoutes.data.length > 0
            ? salesGroupsRoutes.data?.sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)))
              .map(({ route, day }) => ({
                label: `${route} - ${dayNames[day]}`,
                value: JSON.stringify({ route, day })
              }))
            : []
        }
      />
    </div>
  )
}
