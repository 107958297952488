import { Select } from 'antd'
import { formatDeliveryOption } from '../../Boxes'
import { useUrlDeliveryParams } from '../../hooks/useUrlDeliveryParams'

export const DeliveryFilter: React.FC = () => {

  const {
    deliveryParams,
    changeDeliveryParams,
    deliveryZones,
    getDeliveryOptionsFromZone
  } = useUrlDeliveryParams()

  return (
    <>
      <div className='flex mb-4 ml-1 mr-3'>
        <p className='mr-2'>Zona de Entrega:</p>
        <Select
          value={deliveryParams.deliveryZoneId}
          onChange={((deliveryZoneId) => changeDeliveryParams({ deliveryZoneId }))}
          className='flex self-center w-40 h-8'
          showSearch
          allowClear
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={deliveryZones.sort((a, b) => a.name.localeCompare(b.name)).map((zone) => {
            return { label: zone.name, value: zone.deliveryZoneId }
          })}
        />
      </div>
      <div className='flex mb-4 ml-1 mr-3'>
        <p className='mr-2'>Opción de Entrega:</p>
        <Select
          value={deliveryParams.deliveryOptionIds}
          mode={'multiple'}
          maxTagCount={'responsive'}
          onChange={((deliveryOptionIds) => changeDeliveryParams({ deliveryZoneId: deliveryParams.deliveryZoneId, deliveryOptionIds }))}
          className='block self-center w-96 h-8 mr-2'
          showSearch
          allowClear
          disabled={!deliveryParams.deliveryZoneId}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={getDeliveryOptionsFromZone(deliveryParams.deliveryZoneId).map((option) =>
            ({ label: formatDeliveryOption(option), value: option.deliveryOptionId }))}
        />
      </div>
    </>
  )
}