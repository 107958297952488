import React, { useState, useDeferredValue } from 'react'
import {
  Form,
  Select,
  InputNumber,
  Space,
  Button,
  Card
} from 'antd'
import { SingleLabelTemplate } from './components/label/SingleLabelTemplate'
import { MacsaTemplate } from './components/label/MacsaTemplate'
import { addWeeks, startOfWeek } from '@wolf/dates'
import { Helmet } from 'react-helmet-async'
import { DatePicker } from '../../components/DatePicker'
import { LoadingOutlined, PrinterOutlined } from '@ant-design/icons'
import { trpc } from '../../utils/trpc'
import { usePageOutletContext } from '../../page-outlet-context'

type LabelFormInput = {
  productId: number
  quantity: number
  expiration: Date
  labelType: keyof typeof typeLabel
  salesGroupId: number
}

const typeLabel = {
  single: 'single',
  macsa: 'macsa'
} as const

const operations: Array<{ label: string; value: string }> = [
  { label: 'Normal', value: typeLabel.single },
  { label: 'Pequeña', value: typeLabel.macsa },
]

export const Labels: React.FC = () => {
  const [form] = Form.useForm<LabelFormInput>()
  const [productInput, setProductInput] = useState<string>()
  const deferredInput = useDeferredValue(productInput)
  const { sourceFilter } = usePageOutletContext()

  const [openLabelTemplate, setOpenLabelTemplate] = useState<boolean>(false)

  // TODO: Uncomment once we migrated fully from trastaca
  //const salesGroupQuery = trpc.salesGroups.getSalesGroups.useQuery(sourceFilter.serialId).data

  const productsQuery = trpc.products.getProductsIdAndName.useQuery({
    productId: deferredInput,
    sourceId: sourceFilter.serialId
  }, {
    staleTime: 1000 * 60 * 5 // 5 minutes
  })

  const handleSearch = (newValue: string) => {
    setProductInput(newValue)
  }

  const onFinish = () => {
    setOpenLabelTemplate(true)
  }

  return (
    <>
      <Helmet
        title='Etiquetas - Wolf'
      />
      <Card title="Generar etiqueta" className="mb-4">
        <Form layout="inline" form={form} onFinish={onFinish}>
          <Form.Item
            label="Producto"
            name="productId"
            rules={[{ required: true, message: 'Seleccionar producto' }]}
          >
            <Select
              placeholder="Merluza con salsa marinera, patatas, brócoli, zanahorias y pimientos | 811"
              value={productInput}
              className="min-w-[500px]"
              allowClear
              showSearch
              defaultActiveFirstOption={false}
              suffixIcon={null}
              filterOption={false}
              onSearch={handleSearch}
              notFoundContent={null}
              options={
                productsQuery.data?.map((product) => ({
                  label: `${product.name} | ${product.productId}`,
                  value: product.productId,
                })) ?? []
              }
            />
          </Form.Item>
          <Form.Item
            label="Cantidad"
            name="quantity"
            rules={[{ required: true, message: 'Introducir cantidad' }]}
          >
            <InputNumber min={1} placeholder="0" />
          </Form.Item>
          <Form.Item
            label="Caducidad"
            name="expiration"
            rules={[{ required: true, message: 'Introducir fecha' }]}
            initialValue={startOfWeek(addWeeks(new Date(), 2))}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Tipo"
            name="labelType"
            initialValue={'single'}
            rules={[{ required: true, message: 'Seleccionar tipo' }]}
          >
            <Select options={operations} />
          </Form.Item>
          {/* TODO: Uncomment once we migrated fully from trastaca
          <Form.Item
            label="Semana"
            name="salesGroupId"
            rules={[{ required: true, message: 'Seleccionar semana' }]}
          >
            <Select
              placeholder="202201 - 01/01/2022 -> 08/01/2022"
              showSearch
              options={
                salesGroupQuery &&
                salesGroupQuery.map((salesGroup) => {
                  return {
                    label: `${salesGroup.salesGroupId} -
                    ${format(salesGroup.fromDate, 'dd/MM/yyyy')} -> ${format(salesGroup.toDate, 'dd/MM/yyyy')}`,
                    value: salesGroup.salesGroupId,
                  }
                })
              }
            />
          </Form.Item>
          */}
          <Form.Item>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                disabled={openLabelTemplate}
                icon={openLabelTemplate ? <LoadingOutlined /> : <PrinterOutlined />}
              >
                Descargar
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
      {openLabelTemplate && form.getFieldsValue().labelType === typeLabel.single && (
        <SingleLabelTemplate
          onClose={() => {
            form.resetFields()
            setOpenLabelTemplate(false)
          }}
          productId={form.getFieldsValue().productId}
          expirationDate={form.getFieldsValue().expiration}
          quantity={form.getFieldsValue().quantity}
        />
      )}
      {openLabelTemplate && form.getFieldsValue().labelType === typeLabel.macsa && (
        <MacsaTemplate
          onClose={() => {
            form.resetFields()
            setOpenLabelTemplate(false)
          }}
          productId={form.getFieldsValue().productId}
          expirationDate={form.getFieldsValue().expiration}
          quantity={form.getFieldsValue().quantity}
        />
      )}
    </>
  )
}
