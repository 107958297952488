import * as Sentry from '@sentry/react'

type Params = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any | Error
  user?: unknown
  tags?: Record<string, string | number | bigint | boolean | symbol | null | undefined>
}

export const registerSentryError = ({ error, user, tags = {} }: Params) => {
  Sentry.withScope((scope) => {
    if (user) {
      scope.setUser(user)
    }
    if (error.path && Array.isArray(error.path) && error.path.length) {
      scope.setTransactionName(error.path[0])
    } else {
      scope.setTransactionName('Page.js')
    }
    for (const tag in tags) {
      scope.setTag(tag, tags[tag])
    }
    Sentry.captureException(error)
  })
}
