import { Header } from 'antd/es/layout/layout'
import { ComponentProps } from 'react'
import { SalesGroupPicker } from './SalesGroupPicker'
import { SourcePicker } from './SourcePicker'

type HeaderProps = {
  workspace: string
} & Pick<ComponentProps<typeof SalesGroupPicker>, 'salesGroupFilter' | 'setSalesPlanFilter'>
& Pick<ComponentProps<typeof SourcePicker>, 'sourceFilter' | 'setSourceFilter'>


export const PageHeader: React.FC<HeaderProps> = ({ workspace, salesGroupFilter, setSalesPlanFilter, sourceFilter, setSourceFilter }) => {
  return (
    <Header>
      <div className='text-white'>
        <SourcePicker sourceFilter={sourceFilter} setSourceFilter={setSourceFilter} /> | Workspace: {workspace} | <SalesGroupPicker salesGroupFilter={salesGroupFilter} setSalesPlanFilter={setSalesPlanFilter} />
      </div>
    </Header>
  )
}