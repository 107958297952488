import React, { useEffect } from 'react'
import { Select, Space } from 'antd'
import { trpc } from '../utils/trpc'
import type { Models } from '@wolf/api'
import { useLocation } from 'react-router-dom'
import { useUrlGlobalFilterParams } from '../useUrlGlobalFilterParams'

type Props = {
  sourceFilter: Models.SourceFilterType
  setSourceFilter: React.Dispatch<React.SetStateAction<Models.SourceFilterType>>
}

export const SourcePicker: React.FC<Props> = ({
  sourceFilter,
  setSourceFilter,
}) => {
  const { changeSourceParams, sourceFilterParams } = useUrlGlobalFilterParams()

  const sourcesQuery = trpc.sources.getSources.useQuery(undefined, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 6 // 5 minutes
  }).data

  const location = useLocation()

  useEffect(() => {
    if (sourceFilter.serialId !== -1) {
      // Set url params from global filter
      if (
        !sourceFilterParams.serialId ||
        !sourceFilterParams.name
      ) {
        return changeSourceParams({
          serialId: sourceFilter.serialId,
          replace: true,
        })
      }
      if (
        sourceFilterParams.serialId !== sourceFilter.serialId
      ) {
        return setSourceFilter({
          serialId: sourceFilterParams.serialId,
          name: sourceFilterParams.name,
          language: sourceFilterParams.language
        })
      }
    }
  }, [location])

  return (
    <>
      Source:{' '}
      <Space>
        <Select
          value={sourceFilter.serialId}
          size="small"
          onChange={(value) => {
            const source = sourcesQuery?.find(
              (source) => source.serialId === value
            )
            setSourceFilter({
              serialId: value,
              name: source?.name || 'WETACA',
              language: source?.language
            })
            changeSourceParams({ serialId: Number(value) })
          }}
          style={{ minWidth: '10rem' }}
          showSearch
          options={
            sourcesQuery &&
            sourcesQuery.map((source) => {
              return {
                label: source.name,
                value: source.serialId,
              }
            })
          }
        />
      </Space>
    </>
  )
}
