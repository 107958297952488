import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Outlet, Route, Routes } from 'react-router-dom'
import { BoxPicking } from './BoxPicking'
import { BoxSearcher } from './BoxSearcher'

export const Picking: React.FC = () => {

  return (
    <>
      <Helmet
        title='Montajes - Wolf'
      />
      <Routes>
        <Route path=':id' element={<BoxPicking />}>
        </Route>
        <Route path='/' element={<BoxSearcher />}>
        </Route>
      </Routes>
      <Outlet />
    </>
  )

}