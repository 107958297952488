import { LoadingOutlined } from '@ant-design/icons'
import { Button, Card, Col, Form, Input, Row, Typography } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { trpc } from '../utils/trpc'
import { Helmet } from 'react-helmet-async'

export const AccessTokenKey = 'accessToken'
export const AccessTokenKeyExpiration = 'accessTokenExpiration'

export const useUserLoggedTrack = () => {
  const navigate = useNavigate()
  const logout = useLogout()

  React.useEffect(() => {
    const timer = setInterval(() => {
      const expirationTime = localStorage.getItem(AccessTokenKeyExpiration)

      if (!expirationTime) {
        return
      }

      if (+expirationTime < new Date().getTime()) {
        return logout()
      }
    }, 5000)
    return () => clearTimeout(timer)
  }, [])

  React.useEffect(() => {
    const checkUser = () => {
      const accessToken = localStorage.getItem(AccessTokenKey)

      if (!accessToken) {
        navigate('/login')
      }
    }

    checkUser()

    window.addEventListener('storage', checkUser)

    return () => {
      // When the component unmounts remove the event listener
      window.removeEventListener('storage', checkUser)
    }
  }, [])
}

export const useLogout = () => {
  const navigate = useNavigate()

  return () => {
    localStorage.removeItem(AccessTokenKey)
    localStorage.removeItem(AccessTokenKeyExpiration)
    navigate('/login')
  }
}

type FormValues = {
  username: string
  password: string
}

export const Login = () => {
  const loginMutation = trpc.session.login.useMutation()

  const navigate = useNavigate()

  React.useEffect(() => {
    if (localStorage.getItem(AccessTokenKey)) {
      navigate('/')
    }
  }, [])

  React.useEffect(() => {
    if (loginMutation.data) {
      localStorage.setItem(AccessTokenKey, loginMutation.data.accessToken)
      localStorage.setItem(AccessTokenKeyExpiration, `${loginMutation.data.expiration}`)

      navigate('/')
    }
  }, [loginMutation.data])

  const login = ({ password, username }: FormValues) => {
    loginMutation.mutate({ password, username })
  }

  return (
    <>
      <Helmet
        title='Login - Wolf'
      />
      <Row align='middle' justify='center' className='h-screen w-screen bg-gray-600'>
        <Col span={10}>
          <Card title="Login">
            <div className='flex flex-col gap-4'>
              {
                loginMutation.isError &&
                <Typography.Text type='danger'>{loginMutation.error.message}</Typography.Text>
              }

              {
                loginMutation.isLoading && <LoadingOutlined />
              }

              <Form<FormValues> layout='vertical' className='w-full' onFinish={login}>
                <Form.Item
                  label="Nombre de usuario"
                  name="username"

                  rules={[{ required: true, message: 'Debe ser un correo electrónico', type: 'email' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Contraseña"
                  name="password"
                  rules={[{ required: true, message: 'Introduce la contraseña' }]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Entrar
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  )
}
