import React, { ReactNode } from 'react'
import { registerSentryError } from './utils/sentry'

type Props = {
  children?: ReactNode
}

export class SentryWatcher extends React.Component<Props> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidCatch(error: any) {
    const tags = {
      component: 'sentry-watcher'
    }
    registerSentryError({ error, tags })
  }

  render() {
    return (
      <>
        {
          this.props.children
        }
      </>
    )
  }
}