import React, { useEffect, useState } from 'react'
import type { ColumnProps } from 'antd/es/table'
import { Button, Checkbox, Descriptions, InputNumber, Table, Tooltip } from 'antd'
import { trpc } from '../../utils/trpc'
import { Link, useParams } from 'react-router-dom'
import { opType } from '@wolf/entities'
import { LeftOutlined } from '@ant-design/icons'
import { usePageOutletContext } from '../../page-outlet-context'
import { Helmet } from 'react-helmet-async'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

interface DataType {
  userId?: string
  lotId?: number
  op: opType
  quantity?: number
  description?: string
}

const columns: Array<ColumnProps<DataType>> = [
  {
    title: 'Usuario',
    dataIndex: 'userId',
    key: 'userId',
  },
  {
    title: 'Lote',
    dataIndex: 'lotId',
    key: 'lotId',
  },
  {
    title: 'Operación',
    dataIndex: 'op',
    key: 'op',
  },
  {
    title: 'Descripción',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Cantidad',
    dataIndex: 'quantity',
    key: 'quantity',
  },
]

export const Product: React.FC = () => {
  const { productId } = useParams<{ productId: string }>()

  const { sourceFilter, salesGroupFilter } = usePageOutletContext()

  const product = trpc.products.getProductStockById.useQuery({
    productId: Number(productId),
    sourceId: sourceFilter.serialId,
    salesGroupFilter
  })

  const [searchLot, setSearchLot] = useState<number | null>(null)

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [showPickingOps, setShowPickingOps] = useState<boolean>(false)

  const queryResult = trpc.stockEntries.getStockEntries.useQuery({
    pagination: {
      currentPage,
      pageSize,
    },
    salesGroupFilter,
    sourceId: sourceFilter.serialId,
    productId: Number(productId),
    lotId: searchLot ?? undefined,
    showPickingOps
  })

  const onPaginationChange = (current: number, pageSize: number) => {
    setCurrentPage(current)
    setPageSize(pageSize)
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [searchLot])

  return (
    <div>
      <Helmet
        title={`${product.data?.name || 'Producto'} - Wolf`}
      />
      <Tooltip title="Atrás" >
        <Link to="/stocks">
          <Button shape="circle" className='mb-4' icon={<LeftOutlined />} />
        </Link>
      </Tooltip>
      <Descriptions bordered>
        <Descriptions.Item label="ID">
          {product.data?.productId}
        </Descriptions.Item>
        <Descriptions.Item label="Nombre">
          {product.data?.name}
        </Descriptions.Item>
        <Descriptions.Item label="Stock">
          {product.data?.stock}
        </Descriptions.Item>
      </Descriptions>

      <div className="flex mb-2 mt-4 items-center">
        <p className="w-fit mr-2">Filtrar por lote:</p>
        <InputNumber
          className="flex self-center w-40 h-8 pl-2"
          placeholder="1234"
          min={0}
          value={searchLot}
          onChange={setSearchLot}
        />
        <Checkbox className='ml-8' onChange={(evt: CheckboxChangeEvent) => setShowPickingOps(evt.target.checked)}>Mostrar picking</Checkbox>
      </div>

      <Table
        columns={columns}
        dataSource={queryResult.data?.result.map((item, index) => ({ ...item, key: index }))}
        pagination={{
          className: 'pr-4',
          total: queryResult.data?.total,
          pageSize,
          current: currentPage,
          showSizeChanger: true,
          onChange: onPaginationChange
        }}
        loading={queryResult.isLoading}
      />
    </div >
  )
}
