import { useSearchParams } from 'react-router-dom'
import { URL_DELIVERY_PARAMS } from './useUrlDeliveryParams'
import { URL_ROUTES_PARAMS } from './useUrlRouteParams'

type FilterTypes = 'route' | 'delivery'

type FilterTypeParamsResponse = {
  filterTypeParams: FilterTypes
  changeFilterTypeParams: (type: FilterTypes) => void
}

export const URL_FILTER_TYPES_PARAMS = {
  TYPE: 'type',
  ...URL_ROUTES_PARAMS,
  ...URL_DELIVERY_PARAMS
} as const

const clearRouteParams = (searchParams: URLSearchParams) => {
  searchParams.delete(URL_FILTER_TYPES_PARAMS.DAY)
  searchParams.delete(URL_FILTER_TYPES_PARAMS.ROUTES)
  searchParams.delete(URL_FILTER_TYPES_PARAMS.WITHOUT_ROUTE)
}

const clearDeliveryParams = (searchParams: URLSearchParams) => {
  searchParams.delete(URL_FILTER_TYPES_PARAMS.DELIVERY_ZONE_ID)
  searchParams.delete(URL_FILTER_TYPES_PARAMS.DELIVERY_OPTION_IDS)
}

export const useUrlFilterTypeParams = (): FilterTypeParamsResponse => {
  const [searchParams, setSearchParams] = useSearchParams()

  const changeFilterTypeParams = (type: FilterTypes) => {

    searchParams.set(URL_FILTER_TYPES_PARAMS.TYPE, type)

    type === 'route' ? clearDeliveryParams(searchParams) : clearRouteParams(searchParams)

    return setSearchParams(searchParams)
  }

  const type = searchParams.get(URL_FILTER_TYPES_PARAMS.TYPE)

  if (!type || (type !== 'route' && type !== 'delivery')) {
    return {
      filterTypeParams: 'route',
      changeFilterTypeParams
    }
  }

  return {
    filterTypeParams: type,
    changeFilterTypeParams
  }

}