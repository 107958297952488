import { PdfFrame } from '../../../../components/labels/PdfFrame'
import bwipjs from 'bwip-js'
import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { trpc } from '../../../../utils/trpc'
import { format } from '@wolf/dates'
import { usePageOutletContext } from '../../../../page-outlet-context'
import { productToOldBarcode } from '@wolf/barcodes'
import { getTranslations } from './TranslationTemplate'

type Props = {
  productId: number
  expirationDate: Date
  quantity: number
  onClose: () => void
}

export const MacsaTemplate: React.FC<Props> = ({
  productId,
  expirationDate,
  quantity,
  onClose
}) => {
  const { sourceFilter, notificationInstance } = usePageOutletContext()

  const t = getTranslations(sourceFilter.language)

  const showError = (errorMessage: string) =>
    notificationInstance.error({
      message: 'Error en formulario',
      description: errorMessage,
    })

  const productQuery = trpc.products.getProductById.useQuery({
    productId: productId,
    sourceId: sourceFilter.serialId,
  }, {
    retry: false,
    onError: (error) => {
      showError(error.message)
    },
  })

  const contentArray = new Array(quantity).fill(0)

  if (productQuery.isLoading) {
    return <LoadingOutlined />
  }

  if (!productQuery.data) {
    return <></>
  }

  const { data: product } = productQuery

  const barcodes = contentArray.map((_, index) => {
    /* TODO: Uncomment once we migrated fully from trastaca
    const productCode = {
      sourceId: source.serialId,
      workspaceId: workspace.serialId,
      salesGroupId,
      productId: product.productId,
      date: now,
      index: index + 1,
    }

    const dataMatrixCodeText = productV2ToCode(productCode)
    */

    const productOldCode = {
      productId: product.productId,
      date: new Date(),
      index: index + 1,
    }

    const barcodeText = productToOldBarcode(productOldCode)

    return {
      /* TODO: Uncomment once we migrated fully from trastaca
      datamatrix: {
        imageDataURL: bwipjs
          .toCanvas(document.createElement('canvas'), {
            bcid: 'datamatrix',
            text: dataMatrixCodeText,
          })
          .toDataURL('image/png'),
        codeText: dataMatrixCodeText
      },
      */
      barcode: {
        imageDataURL: bwipjs
          .toCanvas(document.createElement('canvas'), {
            bcid: 'code128',
            text: barcodeText,
          })
          .toDataURL('image/png'),
        codeText: `L-${barcodeText}`
      }
    }
  })

  return (
    <PdfFrame onClose={onClose} waitingTime={10000}>
      <link type="text/css" rel="Stylesheet" href="/styles/MacsaTemplate.css" />
      {contentArray.map((_, index) => {
        return (
          <div className="container" key={index}>
            {/* TODO: Uncomment once we migrated fully from trastaca
            <div className="barcodeContainer">
              <img src={barcodes[index].imageDataURL} />
              <p className="barcodeContainer-text">{barcodes[index].codeText}</p>
            </div>
            */}

            <div className="barcodeContainer">
              <img src={barcodes[index].barcode.imageDataURL} />
              <p className="barcodeContainer-text">{barcodes[index].barcode.codeText}</p>
            </div>

            <div className="itemsContainer">
              <div className="items">
                <div className="item">
                  <img width="38%" src="/images/calendar.svg" />
                  <p className="itemsInfo">
                    {format(expirationDate, 'dd/MM/yyyy')}
                  </p>
                </div>
                <div className="item">
                  <img width="23%" src="/images/bascula.svg" />
                  <p className="itemsInfo">{product.weight} g</p>
                </div>
              </div>

              <div className="cookingContainer">
                <img width="30%" src={`/images/noheat.svg`} />
              </div>
            </div>

            <div className="textContainer">
              <p className="name">{product.name}</p>
              <p className="info">
                {t.macsaWarning}
              </p>
            </div>
          </div>
        )
      })}
    </PdfFrame>
  )
}
