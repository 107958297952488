import type { Models } from '@wolf/api'
import { NotificationInstance } from 'antd/es/notification/interface'
import { useOutletContext } from 'react-router-dom'
import { RouterOutput } from './utils/trpc'

type OutletContextType = {
  workspace: RouterOutput['config']['getDefaultConfig']['workspace']
  defaultSource: RouterOutput['config']['getDefaultConfig']['defaultSource']
  salesGroupFilter: Models.SalesGroupFilterType
  sourceFilter: Models.SourceFilterType
  notificationInstance: NotificationInstance
  defaultSalesGroup: RouterOutput['config']['getDefaultConfig']['defaultSalesGroup']
}

export const usePageOutletContext = useOutletContext<OutletContextType>
