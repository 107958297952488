import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { SentryWatcher } from './SentryWatcher'
import { Router } from './Router'
import { trpc, trpcClient } from './utils/trpc'
import * as Sentry from '@sentry/react'
import { env } from './env'

const ReactQueryDevtoolsProduction = React.lazy(() =>
  // eslint-disable-next-line import/extensions
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
)

export const App = () => {
  const [queryClient] = React.useState(() => new QueryClient())
  const [showDevtools, setShowDevtools] = React.useState(false)

  if (env.REACT_APP_VERSION !== 'local' && !!env.REACT_APP_SENTRY_DSN_REACT) {
    Sentry.init({
      dsn: env.REACT_APP_SENTRY_DSN_REACT,
      release: env.REACT_APP_VERSION === 'local' ? undefined : env.REACT_APP_VERSION,
      environment: env.REACT_APP_VERSION || 'not-set',
      normalizeDepth: 10,
      ignoreErrors: [
        // Ignore known errors
        'Object Not Found Matching Id'
      ]
    })
  }

  React.useEffect(() => {
    window.toggleDevtools = () => setShowDevtools((old) => !old)
  }, [])

  return (
    <SentryWatcher>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <Router />
            <ReactQueryDevtools initialIsOpen />
            {showDevtools && (
              <React.Suspense fallback={null}>
                <ReactQueryDevtoolsProduction />
              </React.Suspense>
            )}
          </QueryClientProvider>
        </HelmetProvider>
      </trpc.Provider>
    </SentryWatcher>
  )
}
