import { useSearchParams } from 'react-router-dom'

type IdsParams = {
  boxId?: number
  deliveryId?: number
  orderId?: number
}

type IdsParamsResponse = {
  idsParams: IdsParams
  changeIdsParams: (params: IdsParams) => void
}

export const URL_IDS_PARAMS = {
  BOX_ID: 'boxId',
  ORDER_ID: 'orderId',
  DELIVERY_ID: 'deliveryId'
} as const


export const useUrlIdsParams = (): IdsParamsResponse => {
  const [searchParams, setSearchParams] = useSearchParams()

  const changeIdsParams = ({ boxId, deliveryId, orderId }: IdsParams) => {

    if (boxId) {
      searchParams.set(URL_IDS_PARAMS.BOX_ID, boxId.toString())
    } else {
      searchParams.delete(URL_IDS_PARAMS.BOX_ID)
    }

    if (deliveryId) {
      searchParams.set(URL_IDS_PARAMS.DELIVERY_ID, deliveryId.toString())
    } else {
      searchParams.delete(URL_IDS_PARAMS.DELIVERY_ID)
    }

    if (orderId) {
      searchParams.set(URL_IDS_PARAMS.ORDER_ID, orderId.toString())
    } else {
      searchParams.delete(URL_IDS_PARAMS.ORDER_ID)
    }

    return setSearchParams(searchParams)
  }

  const boxId = searchParams.get(URL_IDS_PARAMS.BOX_ID)
    ? Number(searchParams.get(URL_IDS_PARAMS.BOX_ID))
    : undefined

  const deliveryId = searchParams.get(URL_IDS_PARAMS.DELIVERY_ID)
    ? Number(searchParams.get(URL_IDS_PARAMS.DELIVERY_ID))
    : undefined

  const orderId = searchParams.get(URL_IDS_PARAMS.ORDER_ID)
    ? Number(searchParams.get(URL_IDS_PARAMS.ORDER_ID))
    : undefined

  return {
    idsParams: {
      boxId,
      deliveryId,
      orderId
    },
    changeIdsParams
  }

}
