import { SourceModel } from '@wolf/entities'

type NutritionFacts = {
  [key: string]: string
  energy: string
  totalFat: string
  saturatedFat: string
  hydrates: string
  sugars: string
  fiber: string
  proteins: string
  salt: string
}

type Allergens = {
  [key: string]: string
  peanuts: string
  mustard: string
  soybeans: string
  sesame: string
  crustaceans: string
  milk: string
  egg: string
  fish: string
  sulphur: string
  molluscs: string
  gluten: string
  nuts: string
  celery: string
  lupine: string
}

type Translation = {
  expirationDate: string
  protectAtmosphere: string
  macsaWarning: string
  allergensWarning: string
  nutritionFactsTitle: string
  per100g: string
  serving: string
  ingredients: string
  notHeat: string
  allergens: Allergens
  nutritionFacts: NutritionFacts
}

const translations: Record<'ES' | 'DE' | 'FR' | 'NL', Translation> = {
  ES: {
    expirationDate: 'Fecha de caducidad',
    protectAtmosphere: 'Envasado en atmósfera protectora. Una vez abierto, consumir antes de 24 horas.',
    macsaWarning: 'Una vez abierto, consumir antes de 24 horas.',
    allergensWarning: 'Este plato puede contener otros alérgenos ya que, por el momento, todos los platos se elaboran artesanalmente en el mismo obrador.',
    nutritionFactsTitle: 'Valor nutricional',
    per100g: 'Por cada 100 g',
    serving: 'Ración',
    ingredients: 'Ingredientes',
    notHeat: 'No calentar',
    allergens: {
      peanuts: 'Cacahuetes',
      mustard: 'Mostaza',
      soybeans: 'Soja',
      sesame: 'Sésamo',
      crustaceans: 'Crustáceos',
      milk: 'Lácteos',
      egg: 'Huevos',
      fish: 'Pescado',
      sulphur: 'Sulfitos',
      molluscs: 'Moluscos',
      gluten: 'Gluten',
      nuts: 'Frutos secos',
      celery: 'Apio',
      lupine: 'Altramuces',
    },
    nutritionFacts: {
      energy: 'Energía',
      totalFat: 'Grasas Totales',
      saturatedFat: 'Grasas Saturadas',
      hydrates: 'Carbohidratos',
      sugars: 'Azúcares',
      fiber: 'Fibra Dietética',
      proteins: 'Proteínas',
      salt: 'Sal',
    },
  },
  DE: {
    expirationDate: 'Verfallsdatum',
    protectAtmosphere: 'Unter Schutzatmosphäre verpackt. Nach dem Öffnen innerhalb von 24 Stunden verbrauchen.',
    macsaWarning: 'Nach dem Öffnen innerhalb von 24 Stunden verbrauchen.',
    allergensWarning: 'Dieses Gericht kann andere Allergene enthalten, da alle Gerichte derzeit noch handgefertigt in derselben Werkstatt hergestellt werden.',
    nutritionFactsTitle: 'Nährwerte',
    per100g: 'Pro 100 g',
    serving: 'Portion',
    ingredients: 'Zutaten',
    notHeat: 'Nicht erwärmen',
    allergens: {
      peanuts: 'Erdnüsse',
      mustard: 'Senf',
      soybeans: 'Sojabohnen',
      sesame: 'Sesam',
      crustaceans: 'Krustentiere',
      milk: 'Milchprodukte',
      egg: 'Eier',
      fish: 'Fisch',
      sulphur: 'Sulfite',
      molluscs: 'Weichtiere',
      gluten: 'Gluten',
      nuts: 'Nüsse',
      celery: 'Sellerie',
      lupine: 'Lupinen',
    },
    nutritionFacts: {
      energy: 'Energie',
      totalFat: 'Gesamtfett',
      saturatedFat: 'Gesättigte Fette',
      hydrates: 'Kohlenhydrate',
      sugars: 'Zucker',
      fiber: 'Ballaststoffe',
      proteins: 'Proteine',
      salt: 'Salz',
    },
  },
  FR: {
    expirationDate: 'Date de péremption',
    protectAtmosphere: 'Emballé sous atmosphère protectrice. Une fois ouvert, consommer dans les 24 heures.',
    macsaWarning: 'Une fois ouvert, consommer dans les 24 heures.',
    allergensWarning: 'Ce plat peut contenir d’autres allergènes, car tous les plats sont encore préparés artisanalement dans le même atelier.',
    nutritionFactsTitle: 'Valeurs nutritionnelles',
    per100g: 'Pour 100 g',
    serving: 'Portion',
    ingredients: 'Ingrédients',
    notHeat: 'Ne pas chauffer',
    allergens: {
      peanuts: 'Arachides',
      mustard: 'Moutarde',
      soybeans: 'Soja',
      sesame: 'Sésame',
      crustaceans: 'Crustacés',
      milk: 'Produits laitiers',
      egg: 'Œufs',
      fish: 'Poisson',
      sulphur: 'Sulfites',
      molluscs: 'Mollusques',
      gluten: 'Gluten',
      nuts: 'Fruits à coque',
      celery: 'Céleri',
      lupine: 'Lupin',
    },
    nutritionFacts: {
      energy: 'Énergie',
      totalFat: 'Matières grasses',
      saturatedFat: 'Graisses saturées',
      hydrates: 'Glucides',
      sugars: 'Sucres',
      fiber: 'Fibres alimentaires',
      proteins: 'Protéines',
      salt: 'Sel',
    },
  },
  NL: {
    expirationDate: 'Houdbaarheidsdatum',
    protectAtmosphere: 'Verpakt onder beschermende atmosfeer. Na openen binnen 24 uur consumeren.',
    macsaWarning: 'Na openen binnen 24 uur consumeren.',
    allergensWarning: 'Dit gerecht kan andere allergenen bevatten, aangezien alle gerechten nog handmatig in dezelfde werkplaats worden bereid.',
    nutritionFactsTitle: 'Voedingswaarden',
    per100g: 'Per 100 g',
    serving: 'Portie',
    ingredients: 'Ingrediënten',
    notHeat: 'Niet verwarmen',
    allergens: {
      peanuts: 'Pinda’s',
      mustard: 'Mosterd',
      soybeans: 'Sojabonen',
      sesame: 'Sesam',
      crustaceans: 'Schaaldieren',
      milk: 'Zuivelproducten',
      egg: 'Eieren',
      fish: 'Vis',
      sulphur: 'Sulfieten',
      molluscs: 'Weekdieren',
      gluten: 'Gluten',
      nuts: 'Noten',
      celery: 'Selderij',
      lupine: 'Lupine',
    },
    nutritionFacts: {
      energy: 'Energie',
      totalFat: 'Totaal vet',
      saturatedFat: 'Verzadigd vet',
      hydrates: 'Koolhydraten',
      sugars: 'Suikers',
      fiber: 'Voedingsvezels',
      proteins: 'Eiwitten',
      salt: 'Zout',
    },
  },
}

export const getTranslations = (language: SourceModel['language']) => {
  const safeLanguage = language ?? 'ES'
  return translations[safeLanguage] || translations['ES']
}
