import { PdfFrame } from '../../../components/labels/PdfFrame'
import bwipjs from 'bwip-js'
import React from 'react'
import { boxToCode } from '@wolf/barcodes'
import { Address } from '@wolf/entities'
import { usePageOutletContext } from '../../../page-outlet-context'

export type BoxLabelInput = {
  deliveryGroupId: number
  deliveryGroupIndex: number
  deliveryGroupTotalBoxes: number
  boxId: number
  boxTypeName: string
  address: Address
  deliveryOption: string
  route: { day: number; route: string }
}

type Props = {
  boxLabelInput: Array<BoxLabelInput>
  onClose: () => void
}

export const BoxLabelTemplate: React.FC<Props> = ({ boxLabelInput, onClose }) => {

  const { sourceFilter, workspace } = usePageOutletContext()

  const barcodes = boxLabelInput.map((boxInput) => {
    const boxCode = {
      sourceId: sourceFilter.serialId,
      workspaceId: workspace.serialId,
      deliveryGroupId: boxInput.deliveryGroupId,
      boxId: boxInput.boxId
    }

    const codeText = boxToCode(boxCode)

    const options: bwipjs.ToBufferOptions = {
      bcid: 'datamatrix',
      text: codeText,
      scale: 2,
    }

    const imageDataURL = bwipjs
      .toCanvas(document.createElement('canvas'), options)
      .toDataURL('image/png')

    return { imageDataURL, codeText }
  })

  return (
    <PdfFrame onClose={onClose} waitingTime={1000}>
      <link
        type="text/css"
        rel="Stylesheet"
        href="/styles/BoxLabelTemplate.css"
      />
      {boxLabelInput.map((boxInput, index) => {
        return (
          <div className="content" key={index}>
            <div className='corners-info'>
              <p className='delivery-info'>{boxInput.deliveryGroupId}</p>
              <p className='delivery-option-info'>{boxInput.route.route} - {boxInput.deliveryOption}</p>
              <p className='box-id-info'>B{boxInput.boxId}</p>
              <p className='box-type-info'>{boxInput.boxTypeName}</p>
              {
                boxInput.deliveryGroupTotalBoxes > 1
                  ? <p className='box-index-info'>Bulto: {boxInput.deliveryGroupIndex}/{boxInput.deliveryGroupTotalBoxes}</p>
                  : <></>
              }
            </div>
            <div className="column">
              <div className="code">
                <div className="code-icon">
                  <img src={barcodes[index].imageDataURL} />
                  <p className="code-icon-text">{barcodes[index].codeText}</p>
                </div>
              </div>
              <div className='delivery-data'>
                <p>{boxInput.address.firstname} {boxInput.address.lastname}</p>
                <p className='delivery-data__street'>
                  {boxInput.address.street} {boxInput.address.number} {boxInput.address.floor}
                </p>
                <p>
                  {boxInput.address.zipcode} {boxInput.address.city}
                </p>
                <p>Tlfno. {boxInput.address.phone}</p>
              </div>
            </div>
          </div>
        )
      })}
    </PdfFrame>
  )
}
